.editprofilemainContainer {

    height: calc(100vh - 87px);
    width: calc(100vw - 20px);
    background: #ffffff;
    padding-left: 40px;
    padding-bottom: 100px;

    overflow-y: auto;
    margin-left: 10px;
}


.editProfilebackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 0px;
}
.editProfilebackbutton:hover {
  background: transparent;
  color: #666666;
}
.editProfilebackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.editProfilebackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.editProfilebackbutton img {
  width: 15px;
}
.GENERALDETAIL {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #17171d;
}
.GENERALDETAIL span {
  font-weight: bold;
}
.inputdr1 {
  background: #333333;
  border-radius: 30px;
  width: 88px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.edititle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 15px;
  margin: 0px;
  margin-bottom: 5px !important;
}
.inputfirstn {
  background: #333333 !important;
  border-radius: 30px;
  width: 269px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 14px;
  padding-left: 20px;
}
.edifirstname {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 28px;
  margin: 0px;
  margin-bottom: 5px !important;

}
.edilastname {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 28px;
  margin: 0px;
  margin-bottom: 5px !important;

}
.inputlastname {
  background: #333333 !important;
  border-radius: 30px;
  width: 269px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 14px;
  padding-left: 20px;
  
}
.edidisplayname {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 15px;
  margin: 0px;
  margin-bottom: 5px !important;

}
.inputdisplayname {
  background: #333333;
  border-radius: 30px;
  width: 337px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  /* margin-left: 14px; */
}
.inputprofessionaltitle {
  background: #333333;
  border-radius: 30px;
  width: 310px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-left: 14px;
  padding-left: 20px;
}
.ediprofesinaltit {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 28px;
  margin: 0px;
  margin-bottom: 5px !important;

}
.edilocation {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 15px;
  margin: 0px;
  margin-bottom: 5px !important;

  
}
.inputlocationj {
  background: #333333;
  border-radius: 30px;
  width: 337px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 20px;
}
.EditMyPr {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #333333;
}
.DisplayProfile {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #333333;
  margin: 0px;
  margin-bottom: 5px !important;

}
.Pleaseuploada {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  opacity: 0.8;
}
.userimgcirclsd {
  width: 100px;
  height: 100px;
  border: 4px solid #4453d6;
  box-sizing: border-box;
  border-radius: 86px;
}
.imguseruplo {
  width: 95px;
  height: 32px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d;
  border: 2px solid #17171d;
  border-radius: 20px;
  line-height: 27px;
  cursor: pointer;
}
.edisearchhandfggj {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 15px;
  margin: 0px;
  padding-top: 5px;
  margin-bottom: 5px !important;

}
.inputsearchhandlskndej {
  background: #333333 !important;
  border-radius: 30px;
  width: 337px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 33px;
  /* margin-left: 14px; */
}
.ediaboutmeg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #333333;
  opacity: 0.8;
  padding-left: 15px;
  margin: 0px;
  padding-top: 27px;
  margin-bottom: 5px !important;

}
.inputaboutmejdsgc {
  background: #333333;
  border-radius: 30px;
  width: 1039px;
  height: 107px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 15px;
  padding-top: 10px;
  resize: none;
  /* margin-left: 14px; */
}
.EDUCATIONALvfb {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #17171d;
  padding-top: 32px;
}
.EDUCATIONALvfb span {
  font-weight: bold;
}
.Editorad {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  opacity: 0.8;
}
.edudivinfodcs {
  width: 1039px;
  height: 63px;
  background: #333333;
  border-radius: 10px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.infokcdni998 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  padding-left: 32px;
  width: 150px;
}
.Bachelorof {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 26px;
  width: 308px;
}
.Universescdv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  padding-left: 27px;
  width: 575px;
}
.buttonclas {
  background: transparent !important;
  border: none;
  cursor: pointer;
  margin-right: 5px;
  padding-left: 0;
}

.buttoncals img{
  height: 22px;
  width: auto;
}

.addnewbutton {
  width: 173px;
  height: 54px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #17171D !important;
  border: 2px solid #17171D !important;
  border-radius: 30px !important;
  line-height: 18px;
  background: transparent !important;
  margin-top: 10px;
}

.inputfirstn:focus,
.inputlastname:focus,
.inputdisplayname:focus,
.inputprofessionaltitle:focus,
.inputlocationj:focus,
.inputaboutmejdsgc:focus,
.inputsearchhandlskndej:focus {
  background: #333333;
  color: #ffffff;
  outline: none !important;
}
.searchinputaddchanb:focus {
  background: none;
}
.registeredbusinesname {
  font-family: Segoe;
  font-weight: normal;
  font-size: 16px;
  color: #333333;
  opacity: 0.8;
  padding-top: 20px;
}

.registeredBusinessLocation{
  font-size: 14px;
}

.removefdsvbutton {
  width: 173px;
  height: 54px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #17171d !important;
  border: 2px solid #17171d !important;
  border-radius: 30px;
  line-height: 18px;
  background: transparent !important;
  margin-top: 10px;
}
.changefdsvbutton {
  width: 173px;
  height: 54px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  border: 2px solid #17171d;
  border-radius: 30px;
  line-height: 18px;
  background: #17171d !important;
  margin-top: 10px;
  margin-left: 15px;
}
.saveallfdsvbutton {
  height: 40px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  border: none !important;
  line-height: 18px;
  background: #4453d6 !important;
  margin-top: 10px;
  width: 221px;
  height: 54px;
  border-radius: 30px;
}
.adddeducatimodal {
  background: #17171d;
  width: max-content;
  /* height: 395px; */
  border-radius: 20px;
  left: -61%;
  top: -19%;
  transform: translate(-50px, -50px);

}
.EDUCATIONALslcjdl {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #eef3f7;
  padding-left: 10px;
}
.EDUCATIONALslcjdl span {
  font-weight: bold;
}
.unoversitylabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 17px;
  padding-top: 20px;
}
.unoversitylabelinput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 484px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 27px;
}
.yearhdmlabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 35px;
  padding-top: 20px;
}
.yearshjscinput {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  background: #333333;
 
  border-radius: 30px;
  width: 133px;
  height: 49px;
  margin-left: 17px;
}

.mainYearSelectDiv > div{
  background: #333333  !important;
  color: #FFFFFF !important;
  border-radius:30px;
  border: none !important;

}
.mainYearSelectDiv .p-dropdown-trigger{
  border-radius:30px  !important;
}
.mainYearSelectDiv .p-dropdown{
  border-radius:50px !important;
  padding-left:20px;

}


.yearshjscinput .p-dropdown-trigger{
  background: #333333  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
  
}

.yearshjscinput label , .yearshjscinput label , .yearshjscinput label ,.yearshjscinput .p-dropdown-panel {
  background: #333333 !important;
  color: #FFFFFF !important;
  border-radius: 30px  !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  z-index:1024;
  border: 0px !important;
}
.yearshjscinput li{
  color: #FFFFFF !important;
  border-radius: 20px;
}

.degretypeinput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 310px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 27px;

}
.majorcourseinput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 310px;
  height: 49px;
  margin-left: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 27px;
}
.addtoprofiole {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  border: none;
  margin-top: 35px;
}
.profesinmksslcjdl {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #eef3f7;
}
.profesinmksslcjdl span {
  font-weight: bold;
}

.nameoffirm {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
  padding-top: 27px;
  margin-bottom: 5px;
}
.nameoffirminput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 304px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 26px;
}

.experiencety {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 33px;
  padding-top: 27px;
  margin-bottom: 5px;
}
/* .completedYearinput {
  background: #333333 !important;
  border-radius: 30px  !important;
  width: 337px;
  height: 49px;
  margin-left: 17px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
} */
.experiencetyinput {
  background: #333333 !important;
  border-radius: 30px  !important;
  width: 337px;
  height: 49px;
  margin-left: 17px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 20px;
}


.experiencetyinput .p-dropdown-trigger{
  background: #333333  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
  border-radius: 30px !important;

}

.experiencetyinput label , .experiencetyinput label , .experiencetyinput label ,.experiencetyinput .p-dropdown-panel {
  background: #333333 !important;
  color: #FFFFFF !important;
  border-radius: 30px  !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
}
.experiencetyinput li{
  color: #FFFFFF !important;
  border-radius: 20px;
}

.positojkcd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
  padding-top: 27px;
  margin-bottom: 5px;
}

.positioninput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 414px;
  height: 49px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 26px;
}
.startdate {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
  padding-left: 30px;
  padding-top: 27px;
  margin-bottom: 5px;
}
.startdateinput {
  background: #333333;
  /* opacity: 0.7; */
  border-radius: 30px;
  width: 100px;
  height: 49px;
  margin-left: 17px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #a5a5a5;
  padding-left: 20px;
  margin-bottom: 5px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

}
.Imcurren {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
  color: #ffffff;
  opacity: 0.5;
  padding-top: 2px;
  cursor: pointer;
}
.radioclasscd {
  width: 22px;
  height: 22px;
}
.addprofchangebutton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  border: none;
  /* margin-top: 35px; */
}
.addchngbusimne {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #eef3f7;
  padding-left: 20px;
}
.addchngbusimne span {
  font-weight: bold;
}
.addchangebulB {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-top: 26px;
  padding-left: 110px;
}
.searchbaradchange {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 508px;
  height: 49px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.searchinputaddchanb {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #FFFFFF !important;
  border: none;
  background: transparent;
  width: 490px;
}

.addchangemodal {
  background: #17171d;
  width: 725px;
  max-height: 645px;
  border-radius: 20px;
  transform: translate(-50px, -50px);
  
}
.nextadchangebutton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 90px;
}
.searcherroraddchange {
  width: 508px;
  height: 35px;
  background: #d80031;
  border-radius: 10px;
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  line-height: 33px;
  margin: 0 auto;
  margin-top: 10px;
}
.addchangenextmodal {
  background: #17171d;
  width: 725px;
  height: 414px;
  border-radius: 20px;
  left: -61%;
  top: -19%;
  transform: translate(-50px, -50px);
  padding-left: 25px;
  padding-right: 20px;
}

.nextasearcagaibutton {
  width: 327px;
  height: 54px;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 40px;
}
.nextregisternewbbutton {
  width: 327px;
  height: 54px;
  background: transparent;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: 1px solid;
  margin-top: 14px;
}
.oneresultfou {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding-top: 26px;
  padding-left: 100px;
}
.onemoreresuktj {
  background: #4453d6;
  border-radius: 10px;
  width: 508px;
  height: 63px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 34px;
  line-height: 60px;
  margin: 0 auto;
}
.Registeredmodaltexc {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-top: 16px;
}

.noneferftext2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.citycountryrow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 230px;
}

.addchanglastmodal {
  background: #17171d;
  width: 725px;
  height: 495px;
  border-radius: 20px;
  left: -61%;
  top: -19%;
  transform: translate(-50px, -50px);
}
.submitbuttonlastmodal {
  width: 327px;
  height: 54px;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 40px;
}
.Countrylastmod {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
}
.Citylastmodal {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
}
.businessValueContent{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.businessButtonBar{
  width: 100%;
  justify-content: center;
  display: inline-grid;
}
.businessSearchList{
    height: 200px;
    overflow-y: scroll;
    width: 400px;
    margin: auto;
}

/* input[type='radio']:after {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #000000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #FFFFFF;
  padding: 8px;
  right: 0px;
  bottom: 0px;
  position: relative;

}

input[type='radio']:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  background-color: #FFFFFF;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #FFFFFF;

  padding: 8px;
  right: 0px;
  bottom: 0px;
  position: relative;

} */


.filterByTextBusiness{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin-top:10px;
  width: 400px;
  margin: auto;
  padding-top: 10px;
}
.filterByTextBusiness label{
  padding-left:20px;
  padding-top: 10px; 
}
.searchBusinessListEdit{
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  border-radius: 10px;
  background: #333333;
  opacity: 0.9;
  width: 500px;
  margin: auto;

}


.foundResultsEdit{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #FFFFFF;
  padding-left: 100px;
}
.foundResultsEdit span{
  font-weight: bold;
  margin-right: 5px;
}

.editBussinessBoxProfile {
  width: 1040px;
  display: inline-flex; 
  justify-content: space-between;
}
.purpleModalBg{
  background: linear-gradient(106.98deg, #4453D6 21.72%, #571B65 82.19%);
  opacity: 0.8 !important;
}



.currentlyWorkinghere{
  text-align: right;
}
.currentlyWorkinghere [type="radio"]:checked,
.currentlyWorkinghere [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.currentlyWorkinghere [type="radio"]:checked + label,
.currentlyWorkinghere [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    color: #FFFFFF;
}

.currentlyWorkinghere [type="radio"]:checked + label:before,
.currentlyWorkinghere [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 100%;
    background: transparent;
}
.currentlyWorkinghere [type="radio"]:checked + label:after,
.currentlyWorkinghere [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.currentlyWorkinghere [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.currentlyWorkinghere [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}



@media only screen and (min-width: 1025px) and (max-width: 1919px) {

  .editprofilemainContainer{
    width: calc(100vw - 500px);
    
  }
}


@media only  screen and (min-width: 1920px){
.editprofilemainContainer {

  height: calc(100vh - 30px);
  width: calc(100vw - 800px);
  margin-left: 300px;
  padding-left: 30px;
  padding-top: 25px;
  position:absolute;
  top:20px;
}
}
