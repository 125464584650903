.CompleteUse {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #c4c4c4;
  /* padding-top: 45px; */
  display: inline-grid;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.blackBoxProfile {
  background: #17171d50;
  border-radius: 30px;
  width: auto;
  max-width: 1200px;
  min-width: 900px;
  height: max-content;
}
.completeProfileProgressbar {
  width: 327px;
  height: 7px;
  background: #ffffff;
  border-radius: 10px;
  /* text-align: center; */

  /* margin-left: 397px; */
}
.completeProfileProgressbar .progress-bar {
  background-color: #4aea87 !important;
  border-radius: 10px !important;
}
.GENERALDETA {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #eef3f7;
  padding-top: 15px;
}
.addtphetit {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding-left: 15px;
  margin: 0;
  padding-top: 27px;
  margin-bottom: 4px;
}
.inpuadduse {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 70px;
  height: 50px;
  border: none;
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}

.inpuadduse2 {
  background: #000;
  opacity: 0.8;
  border-radius: 30px;
  width: 200px;
  height: 50px;
  border: none;
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}
.inpuadduse3 {
  background: #000;
  /* opacity: 0.7; */
  border-radius: 30px;
  width: 280px;
  height: 50px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-right: 20px;
}
.inpuadduse4 {
  background: #4453d6 !important;
  opacity: 0.7;
  border-radius: 30px;
  width: 350px;
  height: 60px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 35px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.inpuadduse5 {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ffffff;
  opacity: 0.9;
  background: #333333;
  /* opacity: 0.7; */
  border-radius: 30px;
  width: calc(100% - 40px);
  height: 100px;
  padding-left: 20px;
  padding-top: 10px;
  border: none !important;
  outline: none !important;
  resize: none;
  /* background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 1113px;
  height: 85px;
  border: none;
 
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff; */
}
.placeholder {
  height: 95px;
  width: 95px;
  border-radius: 90px;
  border: 2px solid #4453d6;
}
.dzcdxloadon {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  margin-top: 40px;
}
.EDUCATIONAL {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
}
.EDUCATIONAL span {
  font-weight: bold;
}
.Noeducati {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  opacity: 0.5;
  padding-top: 9px;
}
.addedxuhdk {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
}
.DDsssLA {
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding-top: 20px;
  cursor: pointer;
}
.eduaddmo {
  width: 725px;
  min-height: 395px;
  background: #17171d;
  border-radius: 30px;
}
.ADDEDUCATscd {
  padding-top: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
}
.ADDEDUCATscd span {
  font-weight: bold;
}
.inpuaddusemo {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 528px;
  height: 49px;
  border: none;
  margin-left: 5px;
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.addtphetitmo {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  padding-left: 15px;
  margin: 0;
  padding-top: 27px;
}
.profileUserTitle {
  background: #000000;
  opacity: 0.8;
  border-radius: 30px;
  width: 88px;
  height: 49px;
  border: none;
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  align-items: center;
  display: flex;
}
.inpuaddusemo2 {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 150px;
  height: 49px;
  border: none;
  margin-left: 14px;
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.inpuaddusemo3 {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 340px;
  height: 49px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.buttobvh {
  background: #4453d6 !important;
  border-radius: 30px;
  height: 54px;
  width: 327px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  border: none;
  cursor: pointer;
  margin-top: 53px;
}
.adnewbutton {
  border-radius: 30px;
  width: 173px;
  height: 54px;
  background: #4453d6 !important;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  margin-top: 12px;
}
.uploasscxd {
  border-radius: 30px;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  background: #4453d6 !important;
  border: none;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  margin-top: 12px;
}
.detailsduicnd {
  width: 100%;
  height: 63px;
  border-radius: 20px;
  background: #333333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 15px;
}
.ss2000 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-left: 32px;
  color: #ffffff;
}
.Universitzxcd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #c4c4c4;
  padding-right: 25px;
}
.infosubmt {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  padding-top: 19px;
  text-align: center;

  color: #ffffff;
}
.Thankyou {
  padding-top: 29px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
}

.addUSerContainer {
  width: calc(100vw - 10px);
  height: calc(100vh - 127px);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  /* overflow: hidden; */
  /* height: calc(100vh - 127px);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto; */
  /* margin: auto;*/
}
.rootaddUserContainer {
  /* display: flex; */
  /* justify-content: center; */
  /* height: calc(100vh - 90px); */
  /* overflow: hidden; */
  /* overflow-y: auto; */
  /* width: 100%; */
  /* overflow-x: auto; */
  width: calc(100vw - 0px);
}
.actionButtonIcon {
  background: transparent;
  border: none;
}
.actionButtonIcon:hover,
.actionButtonIcon:focus {
  background: transparent;
}
.selectDropdown {
  width: 327px;
  height: 50px;
  background: #33333370 !important;
  color: #ffffff;
  border-radius: 30px !important;
  border: 0px !important;
  -webkit-appearance: none;
  margin-left: 15px;
}

.select-box__value {
  background-color: black;
  border-radius: 30px;
}

/* .emalinppu::before {
  content: "";
  position: absolute;
  top:0;right:0;left:0;bottom:0;
  background: #17171d;
  border-radius: 30px;
  opacity: 0.7;
  z-index: -1;
  width: 100%;
  height: 100%;
} */

.selectDropdown .p-dropdown-trigger {
  background: #33333370 !important;
  color: #ffffff !important;
  margin-right: 8px;
  border-radius: 30px !important;
}
.selectDropdown .p-dropdown-label {
  height: 50px !important;
  padding-left: 20px !important;
}

.selectDropdown label,
.selectDropdown label,
.selectDropdown label,
.selectDropdown .p-dropdown-panel {
  background: #333333 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;

  width: 100% !important;
  padding-left: 10px;
  border: none !important;
}
.selectDropdown li {
  color: #ffffff !important;
  border-radius: 20px;
}

.dropdownborder {
  border-radius: 30px;
  overflow: hidden;
  margin-left: 10px;
  display: inline;
}
.dropdownborder img {
  position: relative;
  width: 15px;
  right: 30px;
  height: 10px;
}
.professionalDateinput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 100px;
  height: 49px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.dateDiv {
  margin-left: 20px;
}

.positionInput {
  background: #333333;
  opacity: 0.7;
  border-radius: 30px;
  width: 420px;
  height: 49px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}

.currentlyworking {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: right;
  margin-right: 25px;
  margin-top: 5px;
  color: #ffffff;
  width: 100%;
  opacity: 0.5;
}
.currentlyworking [type='radio']:checked,
.currentlyworking [type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
.currentlyworking [type='radio']:checked + label,
.currentlyworking [type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  color: #ffffff;
}

.currentlyworking [type='radio']:checked + label:before,
.currentlyworking [type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #ffffff;
  border-radius: 100%;
  background: transparent;
}
.currentlyworking [type='radio']:checked + label:after,
.currentlyworking [type='radio']:not(:checked) + label:after {
  content: '';
  width: 12px;
  height: 12px;
  background: #ffffff;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.currentlyworking [type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.currentlyworking [type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.stepGoBackIcon {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #c4c4c4;
  background: transparent;
  border: none;
}
.stepGoBackIcon:hover {
  background: transparent;
  color: #c4c4c4;
}
.stepGoBackIcon div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.stepGoBackIcon span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.stepGoBackIcon img {
  width: 15px;
}

.searchHandleProfile {
  width: 20px;
  position: relative;
  top: 45px;
  left: 15px;
  z-index: 1;
  font-size: 20px;
}

.placetexj {
  font-size: 18px;
  color: #fff;
}
.placetexj2 {
  color: #fff;
  opacity: 0.5;
  font-style: italic;
}
@media only screen and (min-width: 1920px) {
  .stepGoBackIcon {
    margin-top: 70px;
  }
  .innerContentCompleteProfile {
    width: 1000px;
    margin: auto;
  }
}
