.Completekyc {
  /* width: 327px; */
  /* height: 27px; */
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #c4c4c4;
  /* margin: 0 auto; */
  text-align: center;
  display: inline-grid;
  justify-content: center;
  width: 100%;

  /* margin-top: 70px; */
}
.Personaldetky {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
  margin-top: 28px;
}
.Personaldetky span {
  font-weight: bold;
}
.titlekyc {
  padding: 0 0 4px 15px;
  /* line-height: 0px; */
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px !important;
}
/* .drinpukyc {
    width: 100px;
    height: 49px;
    background: #333333 !important;
    border-radius: 30px;
    border: none;
    padding-left: 15px;
    color: #FFFFFF;
    border: none !important;
    border-radius: 30px !important;
   
  }
  .drinpukyc .p-dropdown-trigger{
    background: transparent  !important;
    color: #FFFFFF !important;
    margin-right: 8px;
  } */
.firstnamekyc {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 323px;
  padding-left: 20px;
  color: #ffffff !important;
}

.lastnamekyc {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 425px;
  padding-left: 20px;
  color: #ffffff !important;
}

.emailKycinput {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 337px;
  padding-left: 20px;
  color: #ffffff !important;
}
.addressKycinput {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 425px;
  padding-left: 20px;
  color: #ffffff !important;
}
.nationalityKycinput {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 284px;
  padding-left: 20px;
  color: #ffffff !important;
}
.kycStateInput {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 181px;
  padding-left: 20px;
  color: #ffffff !important;
}
.postalCodekycInput {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 113px;
  padding-left: 20px;
  color: #ffffff !important;
}
.firstnamlabky {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding-left: 35px;
  margin-bottom: 4px;
}
.countryresky {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0 0 4px 0;
  padding-left: 10px;
}
.IDUPLO {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #eef3f7;
  padding-top: 65px;
  width: 800px;
}

.kycphoneLabel {
  padding: 0 0 4px 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px !important;
}
.kycPhoneInputBox {
  height: 49px;
  width: 310px;
  border-radius: 30px;
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  display: inline-flex;
  padding-left: 20px;
  align-items: center;
}

.kycPhoneInputField {
  height: 49px;
  width: 235px;
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  color: #ffffff !important;
  font-style: normal;
  font-weight: normal;
  font-family: Segoe;
}

.kycGenderlabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0 0 4px 0;
  padding-left: 35px;
}

/* .genderDropdown{
    background: #333333 !important;
    border-radius: 30px;
    border: none;
    height: 49px;
    width: 214px;
    padding-left: 15px;
    color: #FFFFFF;
    border: none !important;
    border-radius: 30px !important;
    margin-left: 20px;
}
.genderDropdown .p-dropdown-trigger{
  background: transparent  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
} */
/* .nationalityDropdown{
  background: #333333 !important;
    border-radius: 30px;
    border: none;
    height: 49px;
    width: 320px;
    padding-left: 15px;
    color: #FFFFFF;
    border: none !important;
    border-radius: 30px !important;
}
.nationalityDropdown .p-dropdown-trigger{
  background: transparent  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
}
.nationalityDropdown label , .genderDropdown label , .drinpukyc label{
  background: transparent !important;
  color: #FFFFFF !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
} */
.errorpaddingleft {
  padding-left: 20px;
}
.stepmaindiv {
  display: inline-flex;
  justify-content: center;
  width: 100vw;
  /* height: 100vh; */
  height: calc(100vh - 90px);
  overflow-y: auto;
  align-items: center;
  padding-bottom: 50px;
}

.kycDropdowns {
  background: #17171d !important;
  border-radius: 30px !important;
  /* width: 235px; */
  height: 49px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  /* opacity: 0.7; */

  /* opacity: 0.9; */
}
.kycDropdowns .p-dropdown {
  background: transparent !important;
  border: none !important;
}

.kycDropdowns .p-dropdown-trigger {
  background: transparent !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}
.kycDropdowns .body .p-dropdown .p-dropdown-trigger {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}

.kycDropdowns label,
.kycDropdowns label,
.kycDropdowns label,
.kycDropdowns .p-dropdown-panel {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  border: none !important;
}
.kycDropdowns li {
  color: #ffffff !important;
  border-radius: 10px;
}
.kycDropdowns li:hover {
  color: #17171d !important;
  background-color: #ffffff !important;
}

.kycDOBpicker {
  background: #17171d !important;
  /* opacity: 0.9; */
  border-radius: 30px;
  width: 170px;
  height: 49px;
  border: none;
  /* margin-left: 14px; */
  padding-left: 15px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff !important;
  align-items: center;
  display: flex;
  cursor: pointer;
}
.completedKYC {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: #ffffff;
  margin-top: 25px;
}

.kyvProgressbar {
  width: 327px;
  height: 7px;
  background: #ffffff !important;
  border-radius: 10px;
  /* margin-left: 31px; */
}
.kyvProgressbar .progress-bar,
.kyvProgressbar .progress-bar {
  background-color: #4aea87 !important;
  border-radius: 10px !important;
}

.inputFieldBoxes {
  margin-top: 11px;
  /* align-items: center; */
  display: inline-flex;
  justify-content: space-between;
}

.personalInfoBox {
  /* background: #17171D;
  opacity: 0.5;
  border-radius: 30px;
  width: 930px; */
  z-index: 2;
  position: relative;
  width: 930px;
  padding: 30px;
  margin-top: 50px;
}
.personalInfoBox::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #17171d;
  border-radius: 30px;
  opacity: 0.5;
  z-index: -1;
}

.kycBoxesBg {
  background: #17171d80 !important;
  /* margin-top: 30px; */
  min-width: 551px;
  min-height: 400px;
  border-radius: 20px;
  padding-top: 25px;
  padding-bottom: 20px;
}

.completeSuccessTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  color: #ffffff;
}
.kycThankyouTitle {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}
.kycSuccessBox {
  text-align: center;
  /* margin:20px;  */
  margin-top: 20px;
}
.kycSuccessBox .succeessImg {
  width: 100px;
  height: 100px;
  margin: 20px;
}

.kycstepGoBackIcon {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #c4c4c4 !important;
  background: transparent !important;
  border: none;
}
.kycstepGoBackIcon:hover {
  background: transparent;
  color: #c4c4c4;
}
.kycstepGoBackIcon div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.kycstepGoBackIcon span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.kycstepGoBackIcon img {
  width: 15px;
}

.KYCregisterButton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-top: 45px;
}

/*  Onfido Start */
.onfido-sdk-ui-Modal-inner {
  background-color: transparent !important;
  color: #ffffff !important;
  font-family: 'Segoe' !important;
  font-style: normal !important;
  border: none !important;
  height: max-content !important;
  /* opacity: 0.3; */
  /* background-color: #17171D50 !important; */
  /* min-height: 400px;
  height: max-content !important; */
  /* opacity: 0.5; */
}

.onfido-sdk-ui-Theme-step {
  min-height: 200px !important;
  height: max-content !important;
}
.onfido-sdk-ui-Theme-content {
  min-height: 300px;
  height: max-content !important;
}
.onfido-sdk-ui-Uploader-uploaderWrapper {
  min-height: 350px;
}

.onfido-sdk-ui-Uploader-contentWrapper {
  height: 12rem !important;
}
.onfido-sdk-ui-PageTitle-titleSpan,
.onfido-sdk-ui-PageTitle-subTitle {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: normal !important;
  border: none !important;
}
.onfido-sdk-ui-NavigationBar-back {
  color: #ffffff !important;
}
.onfido-sdk-ui-NavigationBar-iconBack {
  background-image: url('../../images/icons/kyc/forwordWhite.png') !important;
  transform: rotate(180deg);
}
.onfido-sdk-ui-DocumentSelector-option {
  background-color: #17171d !important;
  color: #ffffff !important;
  border-radius: 20px !important;
  font-family: Segoe !important;
  font-style: normal !important;
  border: none !important;
}

.onfido-sdk-ui-DocumentSelector-hint {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: normal !important;
}
.onfido-sdk-ui-Theme-link {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: normal !important;
  align-items: center;
  display: inline-flex;
  border-bottom: none !important;
}
.onfido-sdk-ui-crossDevice-Intro-list {
  background-color: #17171d !important;
  color: #ffffff !important;
  padding-left: 20px !important;
  padding-top: 20px !important;

  color: #ffffff !important;
  margin-bottom: 30px !important;
  border-radius: 30px !important;
}
.onfido-sdk-ui-crossDevice-Intro-stageMessage-sms,
.onfido-sdk-ui-crossDevice-Intro-stageMessage-take-photos,
.onfido-sdk-ui-crossDevice-Intro-stageMessage-return-to-computer {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: normal !important;
}

.onfido-sdk-ui-Button-button-primary {
  background-color: #4453d6 !important;
  font-family: Segoe !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  border-radius: 30px !important;
  width: 327px !important;
  height: 54px !important;
}
.onfido-sdk-ui-Button-button-secondary {
  /* background-color: #4453D6 !important; */
  font-family: Segoe !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 18px !important;
  border-radius: 30px !important;
  color: #ffffff !important;
  border-radius: 10px;
  border-color: #ffffff !important;
  height: 49px !important;
}

.onfido-sdk-ui-PageTitle-titleWrapper {
  margin: 2em 2.5em 0em !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 43px;
  text-align: center;

  color: #eef3f7 !important;
}
.onfido-sdk-ui-Welcome-text {
  margin-top: 2em !important;
  margin-bottom: 3em !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 28px;
  align-items: center;
  text-align: center;

  color: #ffffff !important;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-label {
  color: #ffffff !important;
  font-family: Segoe !important;
  font-style: normal !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceLink-btn {
  float: inherit !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceLink-numberInputSection {
  display: inline-flex;
  justify-content: center;
}
.onfido-sdk-ui-PhoneNumberInput-phoneNumberContainer input,
.react-phone-number-input__input:focus:focus,
.react-phone-number-input__input::placeholder {
  color: #000000 !important;
}

.onfido-sdk-ui-DocumentSelector-option::after {
  /* background: url('../../images/icons/kyc/forwordWhite.png') no-repeat !important; */

  background: url('../../images/icons/carousel/nextWhiteArrow.svg') no-repeat !important;
  background-size: cover !important;
  height: 46px !important;
  right: 22px !important;
  padding-right: 11px;
  width: 28px !important;
  top: 30% !important;
}
.onfido-sdk-ui-DocumentSelector-icon-passport {
  background: url('../../images/icons/kyc/passport.png') no-repeat !important;
  background-size: cover !important;
  width: 33px !important;
  height: 40px !important;
}
.onfido-sdk-ui-DocumentSelector-icon-driving-licence {
  background: url('../../images/icons/kyc/drivingLicense.png') no-repeat !important;

  background-size: cover !important;
  width: 58px !important;
  height: 40px !important;
}
.onfido-sdk-ui-QRCode-qrCodeHelpIcon {
  background: url('../../images/icons/kyc/questionmark.png') no-repeat !important;
  width: 20px !important;
  height: 20px !important;
  background-size: cover !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceLink-smsLinkOption:before {
  background: url('../../images/icons/kyc/whitePhoneIcon.png') no-repeat !important;
  width: 20px !important;
  height: 35px !important;
  background-size: cover !important;
  margin-right: 7px;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyLinkOption:before {
  background: url('../../images/icons/kyc/linkIcon.png') no-repeat !important;
  width: 20px !important;
  height: 20px !important;
  background-size: cover !important;
  margin-right: 7px;
}

.onfido-sdk-ui-QRCode-qrCodeHelp {
  min-height: 20px !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceLink-viewOptions {
  /* margin: 0px !important; */
  display: inline-flex;
  justify-content: center;
}

.onfido-sdk-ui-crossDevice-CrossDeviceLink-linkText {
  font-family: Segoe !important;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #ffffff !important;
}
.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard {
  font-family: Segoe !important;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  color: #ffffff !important;
}

.onfido-sdk-ui-Confirm-btn-secondary {
  margin-right: 0;
}

/* Text below camera blocking the image */
.onfido-sdk-ui-CameraPermissions-Primer-instructions {
  margin: 0 0 10px 0 !important;
}

/* Onfido close */
.onfido-sdk-ui-DocumentSelector-icon
  .onfido-sdk-ui-DocumentSelector-icon-passport {
  width: 100px !important;
}

.onfido-sdk-ui-CameraPermissions-Primer-bodyWrapper {
  height: 170px !important ;
}
