.message {
  flex-direction: column;
  width: 100%;
  z-index: 1;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.timeStampMainDiv{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  /* color: #FFFFFF70; */
}

.tickImage{
  object-fit: contain;
  width: 15px;
  height: 10px;
  margin-left: 3px;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
  margin-left: 15px;
  margin-right: 20px;
  padding-left: 50px;

}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.mine.start , .message.other.start{
  margin-top: 25px;
}

.message.mine.start .bubble-container .bubble {
  margin-top: 5px;
}
.message.other.start .bubble-container .bubble {
  margin-top: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.mine .bubble-container .bubble {
  border-top-right-radius: 0px !important;
}

.other .bubble-container .bubble {
  border-top-left-radius: 0px !important;
}

.message.mine.end .bubble-container .bubble {
  /* border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px; */
}

.message.other.end .bubble-container .bubble {
  /* border-radius: 10px;  */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.paddingBubbleContent{
  padding: 10px 15px !important;
}

.productBubbleContent{
  padding-bottom: 10px !important;
  /* border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important; */
}

.message.start .bubble.bubblesend .productBubbleContent{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 10px !important;
}

.message.start .bubble.bubblereceive .productBubbleContent {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 0px !important;
}


.message.end .bubble .productBubbleContent {
  border-bottom-left-radius: 10px ;
  border-bottom-right-radius: 10px ;
}


.message .bubble-container .bubble {
  margin: 1px 0px;
  padding: 0px;
  /* padding: 10px 15px; */
  max-width: 40%;
  width: 40%;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  word-break: break-all;
  margin-bottom: 4px;
}
.message .bubble-container .bubblereceive, .message .bubble-container .bubblesend{
  overflow: hidden;
}

@media screen and (max-width: 1920px){
  .message .bubble-container .bubble {
    max-width: 55%;
    width: 55%;
  }
}

@media screen and (max-width: 1130px){
  .message .bubble-container .bubble {
    max-width: 90%;
    width: 90%;
  }
}

.whiteBG{
  background: #FFFFFF !important;
  color: #17171D !important;
}

.message.start .bubble.bubblesend {
  border-top-left-radius: 0;
  border-top-right-radius: 10px;
}

.message.start .bubble.bubblereceive {
  border-top-left-radius: 10px;
  border-top-right-radius: 0;
}

.message.end .bubble {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.message.start.end .bubble {
  border-radius: 10px;
}

.message.middle .receivertrigle{
  display: none;
}

.message.end .receivertrigle{
  display: none;
}

.message.middle .sendertrigle{
  display: none;
}

.message.end .sendertrigle{
  display: none;
}


.message.start.end .sendertrigle {
  display: block
}

.message.start.end .receivertrigle {
  display: block
}


.message .bubble-container .bubblesend{
  background: #FFFFFF ;
  color: #17171D;
} 
.message .bubble-container .bubblereceive{
  background: #4453D6;
  color: #FFFFFF;
  margin-top: 2px;
} 

.relative{
  /* position: relative; */
}

.receivertrigle{
  height: 0;
  width: 0;
  border-right: 35px solid #FFFFFF;
  border-left: 0px solid transparent;
  border-bottom: 20px solid transparent;
  border-top: 0px solid transparent;
  position: absolute;
  top: 0px;
  left: 30px;
  margin-top: 0;
}

.sendertrigle{
  height: 0;
  width: 0;
  border-right: 35px solid #4453D6;
  border-left: 0px solid transparent;
  border-bottom: 40px solid transparent;
  border-top: 0px solid transparent;
  transform: rotate(-90deg);
  position: absolute;
  top: 0px;
  right: 1px;

}

.messageThreeDotOptions{
  display: flex;
  align-self: flex-end;
  flex-direction: row;
}

.objectFit{
  object-fit: contain;
  width: 10px;
  height: 25px;
}

.alignInRow{
  display: flex;
  flex-direction: row;
  border-width: medium;
  border-style: solid;
}

/* .mainMessageDiv{
  border-width: thick;
  display: flex;
  flex-direction: row;
} */

.messageOptionsBody{
  display: 'flex';
  background:#ffffff;
  border-radius: 15px;
  padding: 15px;
  max-height: 200px;
  /* max-width: 300px; */
}

.showContactList{
  display: 'flex';
  background:rgb(66,83,199);
  border-radius: 15px;
  padding: 15px;
  /* max-height: 300px; */
}

.messageTimingText{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 0;
  margin-bottom: 5px;
  text-align: right;

}

.forwardedText{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;
  /* text-align: right; */
  opacity: 0.8;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-style: italic;
}

.textStyleNormal{
  font-style: normal;
}

.showUserNameBox{
  display: inline-flex;
  vertical-align: middle;
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}




.senderuserImage{
  width: 50px;
  height: 50px;

  border: 2px solid #FFFFFF;
  border-radius: 86px;
}

.chatMarketplaceProduct{
  min-width: 362px;
  width: 100%;
  min-height: 160px;
  /* border-radius: 0px 0px 20px 0px; */
  display: inline-flex;
  /* margin: 10px; */
  cursor: pointer;
  /* margin-right: 10px; */

}
.chatProductImg{
  width: 170px;
  height: 160px;  
  margin-right: 10px;
}
.chatproduct .productTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 25px;
  margin-bottom: 5px;
  color: #17171D;
}
.chatproduct .productPrice{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #17171D;

}
.chatproduct .productlocation{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 0px;

  color: #333333;
}
.bubble-container a{
  text-decoration: underline !important;
}
.bubblereceive a{
  color: #FFFFFF !important;
}



.messageMenuOption {
  position: absolute;
  width: 230px;
  height: max-content;
  border-radius: 10px;
  z-index: 100;
  top: -40px;
  right: 150px;
  background: #FFFFFF;
  padding: 15px;
}
.messageMenuOptionlistText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  display: inline-flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-bottom: 1px dotted #A2A2A5;
}


.forwardMessageModel{
  background:#FFFFFF;
  border-radius: 15px;
  padding: 15px;
  width: 332px;
  padding: 20px;
  /* max-height: 300px; */
}
.shareWithText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #333333;
}
.searchFormemberGroups{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  align-items: center;
  color: #333333;
  opacity: 0.8;

}

.searchMembersInput {
  background: #4453D6;
  border-radius: 30px;
  width: 275px;
  height: 42px;
  display: inline-flex;
  align-items: center;
}
.searchmembersInputs{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #FFFFFF;
  opacity: 0.9;
  background: transparent !important;

}

.messengerForwardName {
  padding-left: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
}

.messengerForwardLastseen{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  padding-left: 10px;
  color: #C4C4C4;
}
.inlineForwardedList{
  display: inline-flex;
  margin-top: 10px;
  border-bottom:1px dotted #A2A2A5;
  width: 100%;
  padding-bottom: 10px;
}
.userListingScroller{
  min-height: 200px;
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  margin-left: 10px;
  cursor: pointer;
}
.userListingScroller::-webkit-scrollbar{
  width: 10px;
}
.userListingScroller::-webkit-scrollbar-thumb{
  background: #4453D6;
  border-radius: 20px;
}
.userListingScroller::-webkit-scrollbar-track{
    background: #4453D650;
    border-radius: 10px;
}
.messengerFiledownloadButton{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  padding-left: 10px;
  color: #FFFFFF;
  border: none !important;
  background: #17171D !important;
  height: 38px;
  border-radius: 10px;
}








