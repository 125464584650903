.EverustextBusinessmanege {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #333333;
}
.editBusinessManageHeading{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;

  color: #333333;
}

.businessprofiemaindivManage{
    padding: 30px;
    height: calc(100vh - 130px);
    overflow-y: auto;
    width: calc(100vw - 10px);
    overflow-x: auto;
    padding-top: 0px !important;
}



.businessprofileEditbuttonmanege {
  width: 234px;
  height: 50px;
  background: transparent !important;
  border-radius: 30px;
  border: 1px solid #4453d6 !important;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #4453d6 !important;
  margin-top: 10px;
}

.businessprofileEditbuttonmanegeBack {
  width: 234px;
  height: 50px;
  background: transparent !important;
  border-radius: 30px;
  border: none !important;

  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF !important;
  background: #4453d6 !important;
}


.backbuttonbusinessManage {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #666666;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.backbuttonbusinessManage:hover {
  background: transparent;
  color: #666666;
}
.backbuttonbusinessManage div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.backbuttonbusinessManage span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #666666;
}
.backbuttonbusinessManage img {
  width: 15px;
}


.businessprofileDetailImageDivManage{
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  /* border: 4px solid #4453D6; */
  position: relative;
}
.businessprofileDetailImageDivManage::before{
  position: absolute;
  content:'';

  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 86px;
  margin-right: 20px;
  border: 4px solid #4453D6;
}
.manageBusinessMemberButton{
  border-radius: 30px;
  border: none !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  padding-right: 15px;
  height: 40px;
}

.requestReceivedBox{
  min-width: 1036px;
  max-width: 1036px;
  width: calc(100vw - 500px);
  background: #17171D;
  border-radius: 10px;
  min-height: 100px;
  padding-bottom: 1px;

}
.businessReceiveRequestLabal{
  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  min-height: 44px;
  min-width: 224px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  width: max-content;
  align-items: center;
  display: inline-flex;
  padding-left: 20px;
  margin-top: 15px;
  padding-right: 30px;
}

.requestReceivedBox .memberManageListing{
  display: inline-flex;
  width: 100%;
  padding-left: 20px;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: space-between;
}
.memberManageListing .memberUserNameBox{
  width: 350px;
}
.memberManageListing .memberGroupListing{
  width: 500px;
}

.memberRequestDate{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;
}
.businessMemberRequestApprove{
  background: #4453D6 !important;
  border-radius: 30px !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  width: 180px !important;
  height: 36px !important;
  border: none !important;
}
.businessMemberRequestRemove{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  border: none !important;
  background: #A5A5A550 !important;
  color: #FFFFFF !important;
  border-radius: 30px !important;
  margin-left: 10px;
  width: 121px !important;
  height: 36px !important;

}
.businessManageUserMember{
  display: inline-flex;
}

.businessManageUserMember .memberProfileBox{
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 86px;
  position: relative;
}
.memberProfileBox::before{
  width: 50px;
  height: 50px;
  border-radius: 86px;
  overflow: hidden;
  border: 2px solid #FFFFFF;
  position: absolute;
  content: '';


}
.businessManageUserMember .memberNameBox{
  margin-left: 10px;
}
.businessManageUserMember .memberNames{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  
  color: #FFFFFF;
}
.requestMemberborder{
  opacity: 0.5;
  border-bottom: 2px dotted #C4C4C4;
  margin-left: 80px;
  margin-right: 25px;
}
.manageGroupBox{
  min-width: 1036px;
  max-width: 1036px;

  width: calc(100vw - 500px);
  background: #4453D6;
  border-radius: 10px;
  min-height: 100px;
  padding-bottom: 20px;

}
.businessmanageGroupBoxLabal{

  background: #17171D;
  border-radius: 0px 30px 30px 0px;
  min-height: 44px;
  min-width: 185px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  width: max-content;
  align-items: center;
  display: inline-flex;
  padding-left: 20px;
  margin-top: 15px;
  padding-right: 30px;

}
.BusinessAddnewGroup{
  background: #FFFFFF !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #17171D !important;
  height: 44px;
  min-width: 183px;
  border: none !important;
  margin-top: 20px;
  margin-right: 25px;
}

.businessMemberListing{
  display: inline-flex;
  margin-left: 20px;
  margin-top: 20px;
  flex-wrap: wrap;

}
.businessGroupsInfo{
  min-height: 55px;
  width: 322px;
  background: #333333;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.businessGroupsInfo .businessgroupNameTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}
.businessGroupsInfo .businessgroupMemberCounts{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: #FFFFFF;

  opacity: 0.7;
}


.manageAdminMembersBox{
  min-width: 1036px;
  max-width: 1036px;

  width: calc(100vw - 500px);
  background: #333333;
  border-radius: 10px;
  min-height: 100px;
  padding-bottom: 20px;

}

.businessmanageMemberGroupBoxLabal{

  background: #4453D6;
  border-radius: 0px 30px 30px 0px;
  height: 44px;
  min-width: 185px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  width: max-content;
  align-items: center;
  display: inline-flex;
  padding-left: 20px;
  margin-top: 10px;
  padding-right: 30px;

}


.managebusinessmemberSearchbox{
  position: relative;
  border-radius: 30px;
  height: 47px;
  width: 337px;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  margin-top: 15px;
  


}
.managebusinessmemberSearchbox::before{
  position: absolute;
  content: '';
  z-index: -1;
  background: #17171D !important;
  /* opacity: 0.5; */
  border-radius: 30px;
  height: 47px;
  width: 337px;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 10px;
}
.managebusinessmemberSearchbox .manageinputSearchBox{
    width: 100%;
    height: 100%;
    background: transparent;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF !important;
    padding-left: 10px;
    padding-right: 10px;

}
.businessManageAdminListing{
  display: inline-flex;
  width: 100%;
  padding-left: 20px;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  /* justify-content: space-between; */
}
.businessManageAdminListing .memberUserNameBox{
  width: 250px;
  margin-left: 15px;
}
.businessManageAdminListing .memberGroupListing{
  width: 570px;
}
.businessManageAdminListing .memberGroupActionBox{
  width: 150px;
}

.memberGroupActionBoxContent{
  display: inline-flex;
  justify-content: space-between;
  width: 150px;
}
.manageBusinessAction{
  background: transparent !important;
  border: none !important;
}

.memberGroupActionBoxContent .businessAdminRight{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* or 117% */

  text-align: center;

  color: #FFFFFF;
} 





.businessManageUserMemberAdmin{
  display: inline-flex;
}

.businessManageUserMemberAdmin .memberProfileBoxAdmin{
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 86px;
  position: relative;
}
.memberProfileBoxAdmin::before{
  width: 50px;
  height: 50px;
  border-radius: 86px;
  overflow: hidden;
  border: 2px solid #FFFFFF;
  position: absolute;
  content: '';


}
.businessManageUserMemberAdmin .memberNameBoxAdmin{
  margin-left: 10px;
}
.businessManageUserMemberAdmin .memberNamesAdmin{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
  
  color: #FFFFFF;
}
.BusinessManagegroupMemberShipTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */

  text-align: center;

  color: #FFFFFF;
}




.businessManageradioButtonsBox [type="checkbox"]:checked,
.businessManageradioButtonsBox [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.businessManageradioButtonsBox [type="checkbox"]:checked + label,
.businessManageradioButtonsBox [type="checkbox"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    color: #FFFFFF;
}

.businessManageradioButtonsBox{
  margin-left: 20px;
  display: inline-flex;
  flex-wrap: wrap;
}


.businessManageradioButtonsBox .businessManageradioButtonItem{
    min-width: 200px;
    margin-top: 5px;
    margin-right: 30px;
    
}

.businessManageradioButtonsBox [type="checkbox"]:checked + label:before,
.businessManageradioButtonsBox [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #FFFFFF;
    border-radius: 100%;
    background: transparent;
}
.businessManageradioButtonsBox [type="checkbox"]:checked + label:after,
.businessManageradioButtonsBox [type="checkbox"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #FFFFFF;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.businessManageradioButtonsBox [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.businessManageradioButtonsBox [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


.addnewBusinessGroupModal{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 23px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 325px;
  max-width: 701px;
  width: max-content;
}
.addnewBusinessGroupModal .modalLabalTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #333333;
}
.addnewBusinessGroupModal .modalgroupNameLabalTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.8;
  color: #333333;
}
.addnewBusinessGroupModal .businessGroupNameinput{
  height: 42px;
  width: 265px;
  border-radius: 30px;
  background: #17171D;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #FFFFFF;
  padding-left: 20px;
  opacity: 0.9;

}
.addnewBusinessGroupModal .groupListbox{
  display: inline-flex;
  align-items: center;
  min-height: 30px;
  width: 100%;

}
.addnewBusinessGroupModal .labalTitles{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
}



.businessManageradioAccessButtonsBox [type="radio"]:checked,
.businessManageradioAccessButtonsBox [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.businessManageradioAccessButtonsBox [type="radio"]:checked + label,
.businessManageradioAccessButtonsBox [type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    color: #333333;
}
.businessManageradioAccessButtonsBox [type="radio"]:checked + label:before,
.businessManageradioAccessButtonsBox [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #333333;
    border-radius: 100%;
    background: transparent;
}
.businessManageradioAccessButtonsBox [type="radio"]:checked + label:after,
.businessManageradioAccessButtonsBox [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #333333;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.businessManageradioAccessButtonsBox [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.businessManageradioAccessButtonsBox [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}



.createBusinessGroupButton{
  background: #4453D6 !important;
  border-radius: 30px !important;
  border: none !important;

  color: #FFFFFF !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  height: 36px;
  min-width: 175px;

}
.addBusinessGroupModal{
  display: inline-flex;
}




.businessManageeditBusinessMemberListing{
  display: inline-flex;
  width: 319px;
  align-items: center;
  border-top: 1px dotted #A2A2A5;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 10px;

  /* justify-content: space-between; */
}
.businessManageeditBusinessMemberListing .memberUserNameBox{
  width: 280px;
}
.businessManageeditBusinessMemberListing .memberGroupActionBox{
  width: max-content;
}


.editBusinessMemberListing{
  display: inline-flex;
  align-items: center;
}

.editBusinessMemberListing .memberProfileBoxImage{
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 86px;
  position: relative;
}
.memberProfileBoxImage::before{
  width: 40px;
  height: 40px;
  border-radius: 86px;
  overflow: hidden;
  border: 2px solid #4453D6;
  position: absolute;
  content: '';


}
.editBusinessMemberListing .memberNameBoxAdmin{
  margin-left: 10px;
}
.editBusinessMemberListing .memberNamesAdmin{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;

  color: #333333;
}



.managebusinessEditmemberSearchbox{
  position: relative;
  border-radius: 30px;
  height: 42px;
  width: 319px;
  z-index: 1;
  overflow: hidden;
  display: inline-flex;
  


}
.managebusinessEditmemberSearchbox::before{
  position: absolute;
  content: '';
  z-index: -1;
  background: #17171D !important;
  /* opacity: 0.5; */
  border-radius: 30px;
  height: 42px;
  width: 319px;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 10px;
}
.managebusinessEditmemberSearchbox .manageinputSearchBox{
    width: 100%;
    height: 100%;
    background: transparent;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #FFFFFF !important;
    padding-left: 10px;
    padding-right: 10px;

}





.businessManageConfirmationModal{
  background: #4453D6;
  border-radius: 30px;
  padding: 23px;
}
.businessManageConfirmationModal .confirmmsg{
  
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.8;
}
.businessManageConfirmationModal .confirmTitle{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  
}
.businessManageConfirmationModal .buttons{
  min-width: 100px;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
  border-radius: 30px;
 
  
  border-radius: 30px;
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #FFFFFF !important;
  border: 0px;

}
.businessManageConfirmationModal .noButton{
  background: transparent !important;
  color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important; 

}
.businessManageConfirmationModal .yesButton{
  background: #17171D !important;
  color: #FFFFFF !important;

  /* border-radius: 30px; */
  margin-left: 10px;
}
.businessManageConfirmationModal .buttonDiv{
  display: inline-flex;
  float: right;
}



@media only screen and (min-width: 1025px) and (max-width: 1919px) {

  .businessprofiemaindivManageManage{
    width: calc(100vw - 500px);
    
  }
}

@media only screen and (min-width: 1920px)   {

  .businessManagementTopContainer{
    display: inline-flex;
    position: absolute;
    top: 10px;
    width: 100%;
  }

  .businessprofiemaindivManage{
    padding: 30px;
      height: calc(100vh - 50px);
      /* overflow-y: auto; */
      width: calc(100vw - 800px);
      margin-left: 330px;
      padding-top: 20px !important;
      /* overflow-x: auto; */
  }
  .backbuttonbusinessManage{
    margin-top: 5px;
  }
 }


