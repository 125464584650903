// Mixins
@mixin border-radius {
  border-radius: 30px;
}
@mixin square-border-radius {
  border-radius: 30px 0 0 30px;
}
@mixin square-border-radius-inverse {
  border-radius: 0 30px 30px 0;
}
// Variables
// Colors
$white: #ffffff;
$black: #17171d;
$button-blue: #4453d6;
$black-opacity: rgba(0, 0, 0, 0.25);
$violet: #8b2fa0;
$grey: rgba(255, 255, 255, 0.1);
$silver: #c4c4c4;
$success-green: #4aea87;
$dark-grey: #333333;
$light-grey: #eeeeee;
$grey-other: #9a9a9a;
$teal: #24a2a8;
// Fonts
$font: Segoe;
//@extend-elements
%extend_1 {
  background: transparent;
  border: none;
  box-shadow: none !important;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-family: $font;
  .notification-user-card-container {
    overflow: hidden;
    flex-direction: column;
    min-width: 500px;
    width: calc(100vw - 500px);
  }
  .contacts-container-parent {
    min-width: 480px;
  }
}
.darkGrayBg {
  background: $black;
}
.startDashboardMetting {
  background: $button-blue;
  border-radius: 30px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  color: $white;
  height: 44px;
  display: flex;
  width: 267px;
  border: none;
  outline: none;
  margin-top: 20px;
  padding-left: 20px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.startDashboardMetting p {
  margin: 0;
}
.purpleNotificationsBg {
  background: linear-gradient(180deg, $button-blue 0, $violet 100%);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.horizontal-menu {
  padding: 15px 0;
}
.user-card-container {
  display: flex;
  flex: 1;
}
.notification-Listcontainer {
  flex: 8;
  padding-left: 30px;
}
.removeButton {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: -5px;
  &:hover {
    @extend %extend_1;
  }
  &:active {
    @extend %extend_1;
  }
  &:focus {
    @extend %extend_1;
  }
  img {
    width: 24px;
  }
}

@media only screen and(min-width: 301px) and(max-width: 800px) {
  .mainDashboardView {
    min-width: 475px;
    max-width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  .mainDashboardContainer {
    position: absolute;
    top: 60px !important;
  }
}
@media only screen and(min-width: 400px) and(max-width: 1025px) {
  .highLightBox-Listcontainer {
    display: inline-flex;
    overflow: hidden;
    width: calc(100vw - 100px) !important;
    overflow-x: auto;
    margin-left: 25px;
    padding-bottom: 5px;
  }
  .message-Listcontainer {
    display: inline-flex;
    overflow: hidden;
    width: calc(100vw - 120px) !important;
    overflow-x: auto;
    margin-left: 15px;
    padding-bottom: 5px;
  }
  .dashBoradInnerContainer {
    height: calc(100vh - 100px) !important;
    overflow-y: auto;
  }
  .dashboard-container .notification-user-card-container {
    min-width: 600px;
    flex-direction: column;
    padding: 20px;
    width: calc(100vw - 20px) !important;
  }
}
@media only screen and(min-width: 1026px) and(max-width: 1919px) {
  .dashBoradInnerContainer {
    width: calc(100vw - 500px);
  }
}
@media only screen and(min-width: 801px) and(max-width: 1200px) {
  .mainDashboardView {
    width: calc(100vw - 1px) !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    position: absolute;
    top: 60px;
  }
  .profileStep-container {
    position: relative;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    overflow: hidden;
    width: auto !important;
    overflow-x: auto;
  }
}
@media only screen and(min-width: 1201px) and(max-width: 1919px) {
  .mainDashboardView {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  .profileStep-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    overflow: hidden;
    width: auto !important;
    overflow-x: auto;
    > div {
      &:nth-of-type(1) {
        width: 30%;
      }
      &:nth-of-type(2) {
        width: 30%;
      }
      &:nth-of-type(3) {
        width: 30%;
      }
      &:nth-of-type(4) {
        width: 30%;
      }
    }

    .profileCompletionBox {
      width: 100%;
      max-height: 155px;
      padding: 20px;
      border-radius: 10px;
      margin: 0;
      overflow: hidden;
    }
  }
  .dashboard-container .notification-user-card-container {
    padding: 30px;
    overflow: hidden;
    flex-direction: column;
    min-width: 500px;
    height: auto;
  }
}

.contactListContainer {
  height: calc(100vh - 200px);
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
}
.dashboardcontactList {
  display: inline-flex;
  border-top: 1px solid $grey;
  width: 100%;
  cursor: pointer;
  align-items: center;
  .imageBox {
    padding: 18px;
  }
  .avatarimage {
    width: 50px;
    border-radius: 100px;
    height: 50px;
  }
}
.dashboardcontactListUsername {
  font-size: 21px;
  margin: 0;
  color: $white;
}
.dashboardcontactListWorkPosition {
  font-style: italic;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
  color: $white;
  opacity: 0.7;
}
.dashboardcontactListWorkPlace {
  font-size: 16px;
  margin: 0;
  color: $white;
  opacity: 0.7;
}
.contactHeader {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 0 30px;
  justify-content: space-between;
  .titleContact {
    font-size: 24px;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    padding-bottom: 13px;
  }
  .searchInput {
    width: 100%;
    height: 47px;
    border-radius: 50px;
    font-style: italic;

    font-size: 14px;
    background: #17171d50;
    padding-left: 50px;
    color: $white;
    &:-ms-input-placeholder {
      color: $white;
    }
  }
  .searchInput::placeholder {
    color: $white;
    opacity: 1;
  }
  .searchInput::-ms-input-placeholder {
    color: $white;
  }
  .searchContactIcon {
    width: 31px;
    position: absolute;
    top: 9px;
    left: 13px;
  }
}
.contactsearchBoxInput {
  width: 100%;
  position: relative;
}
.mainDashboardContainer {
  background: $white;
  position: absolute;
  width: 100vw;
  top: 0;
  padding-top: 10px;
}
.newContactButton {
  background: $white !important;
  @include border-radius();
  font-size: 16px;
  line-height: 22px;
  color: $button-blue !important;
  width: 153px;
}
.notificationButton {
  width: 186px;
  height: 55px;
  background: $black;
  @include square-border-radius-inverse();
  font-size: 21px;
  line-height: 50px;
  align-items: center;
  text-align: center;
  color: $white;
}
.completeProfileTitle {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
  color: $white;
}
.progresPercentage {
  background: $silver;
  height: 7px !important;
  margin-top: 4px;
  .progress-bar {
    border-radius: 99px;
    background: $success-green !important;
  }
}
.profileContainer {
  background: $dark-grey;
  border-radius: 10px;
  margin-bottom: 22px;
}
.profileStep-container {
  position: relative;
  display: flex;
  margin-top: 20px;
  overflow: hidden;
  overflow-x: auto;
}
.profileCompleteTitle {
  font-size: 16px;
  line-height: 22px;
  color: $white;
}
.profileCompleteDescription {
  font-size: 14px;
  line-height: 19px;
  color: $white;
  opacity: 0.6;
}
.profileCompletionBox {
  width: 222px;
  height: 155px;
  max-height: 155px;
  padding: 20px;
  border-radius: 10px;
  margin: 8.5px 10px;
  overflow: hidden;
}
.stepTitle {
  font-size: 16px;
  margin-bottom: 9px;
  font-weight: bold;
}
.stepDescription {
  font-size: 12px;
}
.boxBlackColor {
  color: $dark-grey;
}
.boxWhiteColor {
  color: $white;
}
.boxWhiteBg {
  background: $light-grey;
}
.boxBlueBg {
  background: $button-blue;
}
.boxBlackBg {
  background: $black;
}
.CompletedBlueCircle {
  background: $success-green;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  text-align: center;
  padding: 11px;
  margin: auto;
  position: relative;
  top: -20px;
  img {
    width: 21px;
    height: 21px;
    vertical-align: initial;
    margin-bottom: 3px;
  }
}
.RemainingGrayCircle {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: $silver;
  border-radius: 100px;
  text-align: center;
  padding: 15px;
  margin: auto;
  position: relative;
  top: -20px;
  z-index: 1;
  img {
    width: 21px;
    height: 21px;
    vertical-align: initial;
    margin-bottom: 3px;
  }
}
.relative {
  position: relative;
}
.boxesBorder {
  border-bottom: 2px dashed $grey-other;
  z-index: 0;
  position: relative;
  top: 7px;
}
.allViewnotificationButton {
  width: 117px;
  background: $black;
  @include square-border-radius();
  font-size: 18px;
  line-height: 42px;
  height: 44px;
  align-items: center;
  text-align: center;
  color: $white;
  float: right;
  cursor: pointer;
}
.padding31 {
  padding: 0 38px;
}
.noContacts {
  font-size: 18px;
  line-height: 19px;
  color: $white;
  opacity: 0.6;
  position: relative;
  margin: auto;
  text-align: center;
}
.contactButton {
  width: max-content;
  height: 55px;
  background: $black;
  @include square-border-radius-inverse();
  font-size: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: $white;
  padding-left: 26px;
  padding-right: 26px;
}
.messengerButton {
  width: max-content;
  height: 55px;
  background: $button-blue;
  @include square-border-radius-inverse();
  font-size: 21px;
  line-height: 20px;
  color: $white;
  padding-left: 26px;
  padding-right: 26px;
  align-items: center;
  display: flex;
}
.contacts-Listcontainer {
  display: inline-flex;
  overflow: hidden;
  width: calc(100vw - 100px);
  overflow-x: auto;
  margin-left: 15px;
}
.contactGrayBox {
  width: 360.64px;
  height: 127px;
  background: $dark-grey;
  border-radius: 10px;
  margin-right: 15px;
  .imgBox {
    width: 70px;
    text-align: center;
  }
}
.contactUsername {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: $white;
}
.contactUserposition {
  font-size: 14px;
  height: 20px;
  color: $white;
}
.userInfoBox {
  display: inline-flex;
  padding-top: 10px;
  width: 360px;
}
.usernameBox {
  width: 210px;
  cursor: pointer;
}
.contactRequestDate {
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: $white;
  opacity: 0.8;
}
.approveButton {
  background: $button-blue !important;
  border-radius: 30px;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: $white;
  width: 175.34px;
  height: 36px;
  border: none !important;
}
.removeRequestButton {
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: $black !important;
  width: 111.66px;
  height: 36px;
  text-align: center;
  background: $white !important;
  border-radius: 30px;
  margin-left: 15px;
  border: none !important;
}
.buttonsBox {
  padding: 10px;
  display: inline;
  width: 325px;
  margin-top: 10px;
}
.message-Listcontainer {
  display: inline-flex;
  overflow: hidden;
  width: calc(100vw - 580px);
  overflow-x: auto;
  margin-left: 15px;
  padding-bottom: 5px;
}
.messageWhiteBox {
  width: 323px;
  height: 90px;
  background: $white;
  border-radius: 10px;
  margin-right: 15px;
  .contactUsername {
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    color: $black;
  }
  .contactUserposition {
    font-size: 14px;
    line-height: 19px;
    height: 35px;
    color: $black;
    overflow: hidden;
  }
  .messageInfoBox {
    display: inline-flex;
    padding-top: 10px;
    width: 323px;
  }
  .usernameBox {
    width: 220px;
  }
  .messageRequestDate {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: $white;
    opacity: 0.8;
  }
  .messageBox {
    display: inline-flex;
    padding-top: 0;
    width: 323px;
    .imgBox {
      width: 70px;
      text-align: center;
    }
  }
  .messageDate {
    font-size: 12px;
    display: inline-flex;
    color: $dark-grey;
    padding-left: 5px;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    padding-right: 7px;
  }
  .unreadBox {
    width: 10px;
    height: 10px;
    background: $teal;

    border-radius: 50px;
    margin: 5px;
    margin-right: 5px;
  }
  .imgBox img {
    width: 50px;
    height: 50px;
    border-radius: 86px;
    border: 2px solid $button-blue;
  }
}
.highLightsBox {
  background: $black;
  border-radius: 10px;
  padding-top: 27px;
  padding-bottom: 21px;
  margin-top: 10px;
  margin-bottom: 22px;
}
.highLightSectionHeading {
  width: max-content;
  height: 55px;
  background: $button-blue;
  @include square-border-radius-inverse();
  font-size: 21px;
  align-items: center;
  text-align: center;
  color: $white;
  padding-left: 26px;
  padding-right: 26px;
  display: flex;
}
.highLightBox-Listcontainer {
  display: inline-flex;
  overflow: hidden;
  overflow-x: auto;
  padding: 0 38px;
  padding-bottom: 5px;
  position: relative;
  .highfeedTitle {
    min-width: 72px;
    width: max-content;
    min-height: 30px;
    background: $black;
    @include square-border-radius-inverse();
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    color: $white;
    display: inline-flex;
    position: absolute;
    top: 35px;
    text-transform: uppercase;
    padding-right: 20px;
    padding-left: 20px;
  }
  .WhiteBoxDescription {
    background: $white;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    height: 100%;
  }
  div:nth-of-type(3n) {
    margin-right: 0;
  }
}
.highLightBox {
  height: 191px;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 23px;
  cursor: pointer;
  overflow: hidden;
  width: 33%;
}
.imgContentBox {
  overflow: hidden;
  height: 58%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $dark-grey;
  img {
    width: 100%;
  }
}
.highLightDate {
  font-size: 12px;
  line-height: 16px;
  color: $dark-grey;
  opacity: 0.8;
  margin: 0;
}
.highLightTitle {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: $black;
}
.allViewHighLightsButton {
  width: 117px;
  background: $button-blue !important;
  @include square-border-radius();
  font-size: 18px;
  line-height: 42px;
  height: 44px;
  align-items: center;
  text-align: center;
  color: $white !important;
  float: right;
  cursor: pointer;
}
.purpleNotificationButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 21px;
}
.allViewLatestNotificationButton {
  width: 117px;
  float: right;
  background: $black !important;
  @include square-border-radius();
  font-size: 18px;
  line-height: 42px;
  height: 44px;
  align-items: center;
  text-align: center;
  color: $white !important;
  cursor: pointer;
}
.dashBoradInnerContainer {
  padding: 0 30px 20px 0;
  height: calc(100vh - 15px) !important;
  overflow-y: auto !important;
  overflow: hidden;
}
.DashboardnotificationMessagescontainer {
  padding: 10px;
  width: 328px;
  height: 116px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 19px;
  font-size: 18px;
  line-height: 24px;
  color: $white;
  cursor: pointer;
  overflow: hidden;
  padding-left: 14px;
  .topTitleHeader {
    display: inline-flex;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: $white;
    .headingContainer {
      max-width: 350px;
    }
  }
}
.dashboardnotification-body {
  font-size: 14px;
  line-height: 19px;
  color: $white;
  opacity: 0.6;
}
.dashboardUnreadEllipse {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: $teal;
  margin-right: 6px;
  margin-top: 5px;
}
@media only screen and(min-width: 1920px) {
  .mainDashboardView {
    width: calc(100vw - 1px) !important;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
  }
  .dashBoradInnerContainer {
    padding-left: 300px;
    width: calc(100vw - 500px) !important;
    height: calc(100vh - 10px) !important;
  }
  .mainDashboardContainer {
    background: $white;
    position: absolute;
    top: 0;
  }
  .profileStep-container {
    > div {
      &:nth-of-type(1) {
        width: 50%;
        margin-right: 1%;
      }
      &:nth-of-type(2) {
        width: 50%;
        margin-right: 1%;
      }
      &:nth-of-type(3) {
        width: 50%;
        margin-right: 1%;
      }
      &:nth-of-type(4) {
        width: 50%;
        margin-right: 1%;
      }
    }
    .profileCompletionBox {
      width: 100%;
      max-height: 155px;
      padding: 20px;
      border-radius: 10px;
      margin: 8.5px 10px;
      overflow: hidden;
    }
  }
  .message-Listcontainer {
    width: 97%;
  }
  .dashboard-container .notification-user-card-container {
    overflow: hidden;
    flex-direction: column;
    padding: 20px;
    min-width: 500px;
    width: calc(100vw - 800px);
    overflow-y: auto;
  }
}
.scroll-menu-arrow {
  padding: 10px;
  cursor: pointer;
}
.imgBox img {
  width: 50px;
  height: 50px;
  border-radius: 86px;
  border: 2px solid $white;
}
