.accsedetbox {
    width: 705px;
    min-height: 500px;
    background: #333333;
    border-radius: 10px;
    margin-left: 25px;
    margin-top: 15px;
    padding-bottom: 25px;
  }
  .accsetheading {
    background: #ffffff;
    border-radius: 0px 30px 30px 0px;
    width: 210px;
    height: 44px;
    margin-top: 17px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 41px;
    text-align: center;
    color: #333333;
  }
  .editbuttonacdsj {
    background: #4453d6 !important;
    border-radius: 30px;
    width: 234px;
    height: 49px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-top: 17px;
    text-align: center;
    margin-right: 17px;
    color: #ffffff !important;
  }
  
.settinghjohn {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    color: #ffffff;
    padding-left: 20px;
    margin: 0px;
    padding-top: 18px;
  }
  .settingDrJohn {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    color: #ffffff;
    padding-left: 20px;
    line-height: 50px;
  }
  
  .settingfiernjd {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    padding-left: 44px;
    margin: 0px;
  }
  .settibnjhdkjon {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    padding-left: 44px;
  }
  .settibssnjhdkjon {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    padding-left: 20px;
  }
  .sescsdingfiernjd {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    padding-left: 20px;
    margin: 0px;
  }
  .sescsdinlastnamgf {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    padding-left: 39px;
    margin: 0px;
  }
  .settibssnalenaxal {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    padding-left: 39px;
  }
  .settinguserimg {
    width: 100px;
    height: 100px;
    margin-top: 20px;
    margin-left: 39px;
    border-radius: 100px;
    border: 4px solid #4453D6;

  }
  .seteditaccsedetbox {
    width: 705px;
    height: 631px;
    background: #333333;
    border-radius: 10px;
    margin-left: 25px;
    margin-top: 36px;
  }
  .settingttileinput {
    width: 88px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
  }
  .firstnameinput {
    width: 169px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 20px;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
  }
  .settingtitlelabel {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    margin: 0px;
    padding-left: 33px;
  }
  .settingtfirstnsmla {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    margin: 0px;
    padding-left: 60px;
  }
  .settinglastnlabel {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    margin: 0px;
    padding-left: 33px;
  }
  .lastnameinput {
    width: 169px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 20px;
  }
  .settingaboutlabel {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    margin: 0px;
    padding-left: 60px;
  }
  .aboutsssninput {
    width: 617px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 39px;
    padding-left: 20px;

  }
  .aboutninput {
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    opacity: 0.6;
    margin: 0px;
    padding-left: 39px;
    padding-top: 10px;
    margin-left: 39px;
  }
  
  .emailjcdkninput {
    width: 460px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 39px;
    padding-left: 20px;

  }
  .phonedkninput {
    width: 233px;
    height: 49px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 39px;
    padding-left: 20px;

  }
  .klsjfkejcdkninput {
    width: 617px;
    height: 66px;
    background: #17171d;
    border-radius: 30px;
    border: none;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    margin-left: 39px;
    padding-left: 20px;

  }
  .inputBackGround{
   
    width: 88px;
    height: 49px; 
    border-radius: 30px;
    margin-left: 42px;
  }
  .inputBackGround .select-box__current{
    background: #17171d !important;
  }
  .showUserPlanName{
    background: #17171d;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    padding: 5px;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
    margin-left: 35px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
