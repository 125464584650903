.dropdown-parent {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 0;
  width: 100% !important;
}

.dropdown-label {
  width: 100%;
  text-align: right;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  margin: 0;
}
.menuButton {
  background: transparent;
  border: 0px !important;
  width: 200px;
  padding: 0px;
  margin-left: 20px;
  margin-right: auto;
}
.menuButton img {
  width: 200px !important;
  border: none !important;
  padding: 7px;
  border-radius: 0px !important;
  height: 50px !important;
  /* height: 40px !important; */
}

.menuButton:focus,
.menuButton:hover,
.menuButton:active {
  background: transparent !important;
  border: 0px !important;
  box-shadow: none !important;
}
/* 

@media only screen and (max-width: 600px) and (min-width: 300px){
    .dropdown-label {
        width: 185px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;

    }
    .dropdown-parent div {
        padding: 0;
        margin-right: 2px;
        width: auto;
        z-index: 999999;
    }
    .dropdown{
        display: block;
        background-color: #FFFFFF;
        position: absolute;
        right: 30px;
        top: 5vw;
        width: 200px !important;
    }
}
@media only screen and (min-width: 601px) and (max-width: 1500px){
    .dropdown-label {
        width: 250px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-right: 2px;
    }

}
@media only screen and (min-width: 601px) and (max-width: 1000px){
    .dropdown{
        display: block;
        background-color: #FFFFFF;
        position: absolute;
        right: 30px;
        top: 5vw;
        width: 20vw !important;
    }
}

@media only screen and (min-width: 1501px){
    .dropdown-label {
        width: 250px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        margin-right: 2px;
        width: 20vw !important;
    }
} */

.dropdown-parent img {
  border-radius: 50%;
  border: 2px solid #27c443;
}

.dropdown-parent div {
  padding: 0;
  /* float: right; */
  margin-right: 2px;
  width: auto;
  z-index: 999999;
}

.dropdown-parent .txt-middle {
  margin: auto;
}

.dropdownnewwrapper {
  position: relative;
}

.dropdownArrow {
  position: absolute;
  top: 22px;
  right: 10px;
}

.dropdownnew {
  display: block;
  /* position: absolute; */
  right: 0px;
  top: 40px;
  /* width: 150px;
    padding: 10px; */

  position: absolute;
  width: 158px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* left: 0px;
    top: 18px; */

  background: #17171d;
  opacity: 0.8;
  border-radius: 10px;
  z-index: 100;
}

.dropdownnew div {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #ffffff;
  cursor: pointer;
  padding: 5px 20px;
  /* width: 200px; */
}
.dropdownnew a,
.dropdownnew a:hover {
  color: #ffffff;
  text-decoration: none;
}

.hide {
  display: none;
}
.notificationIconCss {
  /* height: 2vw !important;
    width: 2vw !important; */
  height: auto !important;
  /* max-width: 34px !important; */
  border: none !important;
  margin-right: 10px;
  border-radius: 0px !important;
  vertical-align: initial;
}
.profileboxMargin {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 86px;
}

.profileboxImageContainer {
  position: relative;
  border-radius: 86px;
  overflow: hidden;
  margin-right: 15px;
}

.profileboxImageContainer::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  border-radius: 86px;
  border: 2px solid #4453d6;
}

.menuDisplayHide {
  display: none !important;
}
.profileboxImage {
  border: 2px solid #4453d6;
}
.iconsPaddingTop {
}

.checkdropMenuFlex {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
}

.space-right {
  margin-right: 20px;
}

.userNamePosition {
  display: inline-flex;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 2px;
  cursor: pointer;
}
.userNamePositionTop {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
