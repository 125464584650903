.selleraddproContainer {
  display: inline-flex;
  /* background: linear-gradient(180deg, #4453d6 0%, #571b65 100%); */
  /* height: calc(100vh - 85px); */
  /* width: 100vw; */
}

.selleraddsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.selleraddsbackbutton:hover {
  background: transparent;
  color: #ffffff;
}
.selleraddsbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.selleraddsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff;
}
.selleraddsbackbutton img {
  width: 15px;
}
.selleradddmaindiv {
  display: flex;
  padding: 30px;
  align-items: baseline;
  width: calc(100vw - 500px);
  overflow-x: auto;
  overflow-y: auto;
  height: calc(100vh - 100px);
}
.viewlisgvSiemensAvapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
}
.viewlisgvSiemensAvapro p {
  font-size: 35px;
  margin: 0px;
}
.viewlisordopownpro {
  width: 523px;
  height: 37px;
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 25px; */
  cursor: pointer;
  color: #FFFFFF;
}
.viewrMureproscdv {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.viewlistSystmepro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
}
.viewlistrordMRIpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-top: 12px;
  padding-left: 10px;
}

.viewlistSonyprof {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #333333;
  padding-left: 10px;
}
.viewlstSTIONSpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding-left: 15px;
}
.viewlisteTSeapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: right;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  color: #f5d028;
  padding-left: 250px;
}

.viewlstMcturerpro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #767575;
  padding-left: 15px;
  padding-top: 12px;
}
.viewlistDoReportbutton {
  width: 150px;
  margin: auto;
  background: linear-gradient(180deg, #439fc8 0%, #4358a7 100%);
  border-radius: 5px;
  height: 34px;
  border: none;
  font-size: 14px;
}
.viewlistigCircleImage {
  display: flex;
  margin-left: 16px;
  flex-wrap: wrap;
}
.viewlistigCircleImage img {
  margin: 10px;
}

.viewlistrsnfoheadiung {
  width: 226px;
  height: 44px;
  background: #ffffff;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 42px;
  color: #333333;
  text-align: center;
}

.viewlisttcsdcdpro {
  background: #f5d028;
  border-radius: 30px;
  width: 78px;
  height: 25px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #17171d;
  opacity: 0.8;
  margin-left: 123px;
  line-height: 25px;
}
.viewlistitemorder {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin: 0px;
}
.viewlistAdded {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.7;
  margin: 0px;
}

.viewlistedibutton{
background: #333333 !important;
border-radius: 30px;
width: 169.58px;
height: 40px;
border-radius: 30px;
border:none;
font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
text-align: center;
color: #FFFFFF !important;
margin-right: 10px;
}

.bottomWhiteSpacing{
  background: #FFFFFF;
  height: 10px;
  width: calc(100% - 25px);
}

.sellerActiveYellowCollapse{
  background: #F5D028;
  color: #17171D;
}
.sellerViewListingContentBox{

  display: inline-flex;
  background: #FFFFFF;
   width: calc(100% - 25px);
 }
 .sellerViewListingContentBox > div:nth-of-type(1) {
  min-width: 190px;
}
.sellerViewListingContentBox > div:nth-of-type(2) {
  padding-left: 50px;
  max-width: 250px;
  word-break: break-all;
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .selleradddmaindiv {
    width: calc(100vw - 10px);
  }
}

@media only  screen and (min-width: 1920px){
  .selleradddmaindiv {
    display: flex;
    padding: 30px;
    align-items: baseline;
    width: calc(100vw - 800px);
    overflow-x: auto;
    margin-left: 300px;
  }
}