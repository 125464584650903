// Mixins
@mixin inset {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

@mixin progress-bar {
  width: 100%;
  height: 8px;
  margin-top: 0;
  background-color: $grey;
  border-radius: 10px;
}

@mixin button {
  width: 100%;
  max-width: 327px;
  height: 49px;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  opacity: 0.8;
  padding-left: 60px;
  border: none;
}

@mixin container {
  width: 100%;
  margin: 0 auto;
}

@mixin title {
  font-size: 15px;
  color: $white;
  margin: 0 0 4px;
  align-self: flex-start;
  padding-left: 10px;
}

@mixin setup-acc {
  align-self: flex-start;
  font-size: 20px;
  color: $grey;
}

@mixin form-button {
  height: 49px;
  background: $dark-background;
  // opacity: 0.8;
  border-radius: 30px;
  font-size: 16px;
  color: $white;
  border: none;
}

@mixin dropdown-trigger {
  background: transparent;
  color: $white;
  margin-right: 8px;
  border-radius: 100%;
  height: 40px;
  margin-top: 4px;
}

@mixin dropdown-label {
  background: transparent;
  color: $white;
  padding-top: 12px;
  font-size: 15px;
  border-radius: 30px;
  opacity: 0.5;
  padding-left: 20px;
}

@mixin psuedo-container {
  overflow: hidden;
  margin: 0 auto;
  padding: 1.9rem;
  height: 100%;
  position: relative;
  background-color: transparent;
  z-index: 1;
}
@mixin psuedo-container-before {
  content: '';
  @include inset();
  position: absolute;
  height: 100%;
  margin: 0 auto;
  padding: 1.9rem;
  height: 100%;
  background: $dark-background;
  opacity: 0.5;
  border-radius: 30px;
  z-index: -1;
}
// Variables
// Colors
$white: #fff;
$grey: #c4c4c4;
$dark-background: #17171d;
$progress-blue: #4658d4;
$button-blue: #4453d6;

.div-flex-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  .maineSingupContaner {
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    max-width: 706px;
    // max-height: 755px;
    max-width: 706px;
    overflow: hidden;
    overflow-y: scroll;
    .logo-container {
      padding: 0 1.9rem 1.9rem;
      text-align: center;
      .signUpFlogo {
        margin: 0 auto;
        max-width: 100%;
      }
    }
    .signupstep1::before {
      @include psuedo-container-before();
    }
    .signupstep1 {
      margin: 0 auto;
      padding: 1.9rem;
      height: 100%;
      position: relative;
      background-color: transparent;
      z-index: 1;
      max-width: 387px;
      max-height: 539px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .setupacc2 {
        align-self: flex-start;
        font-size: 20px;
        color: #c4c4c4;
      }
      .progress-container {
        width: 100%;
      }
      .title3 {
        font-size: 15px;
        color: #fff;
        margin: 0 0 4px;
        align-self: flex-start;
        padding-left: 10px;
        margin-bottom: 20px;
        padding-left: 0;
        align-self: flex-start;
      }
      .changePhoneText {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin: 0 0 4px;
        align-self: flex-start;
        padding-left: 10px;
      }
      .cantchangePhoneText {
        font-size: 15px;
        color: #fff;
        margin: 0 0 4px;
        align-self: flex-start;
        padding-left: 10px;
      }
      .phoneInputContainer {
        margin: 0 auto;
        width: 100%;
        .phoneInputBox {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          .selectCountryButton {
            font-size: 16px;
            background: transparent;
            color: #fff;
            border: none;
            border-radius: 20px;
            height: 49px;
            display: inline-flex;
            opacity: 0.8;
            align-items: center;
          }
          .phoneInput {
            height: 49px;
            background-color: #17171d;
            color: #fff;
            flex-grow: 1;
            border-radius: 20px;
            padding-left: 20px;
            opacity: 0.8;
          }
        }
      }
      .stepnextbu {
        width: 100%;
        max-width: 327px;
        height: 49px;
        border-radius: 30px;
        font-size: 16px;
        color: #fff;
        opacity: 0.8;
        padding-left: 60px;
        border: none;
        padding: 0;
        background-color: #4453d6;
        margin-bottom: 10px;
      }
      .otpMobileEllipse {
        margin-bottom: 0;
        display: flex;
        width: 100%;
        max-width: 49px;
        align-items: center;
        justify-content: center;
        height: 49px;
        border-radius: 30px;
        background-color: #fff;
        color: #333333;
        text-align: center;
      }
      .step1inpu {
        width: 80%;
        height: 49px;
        background: #17171d;
        border-radius: 30px;
        font-size: 16px;
        color: #fff;
        opacity: 0.8;
        padding-left: 40px;
        border: none;
      }
    }
  }
}

// Err messages
.redBoxErrorMsg {
  margin: 10px 0;
}

// Setup Account Form
form {
  overflow-y: scroll;
  .signmaindiv::before {
    @include psuedo-container-before();
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
  .signmaindiv {
    @include psuedo-container();
    display: flex;
    flex-direction: column;
    align-items: center;
    .setupacc {
      @include setup-acc();
      padding-left: 27%;
    }
    .insertpersod {
      font-size: 20px;
      margin-bottom: 25px;
      color: $white;
    }
    .inlinePictureOrButton {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      color: $white;
      flex-grow: 0.5;
      .placetexj {
        font-size: 18px;
      }
      .placetexj2 {
        font-size: 16px;
        opacity: 0.5;
      }
      .uploasscxd {
        width: 95px;
        height: 32px;
        background: #4453d6;
        border-radius: 30px;
        margin: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  .signUpProfileBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
    p {
      margin-bottom: 0;
      font-size: 15px;
      color: $white;
    }
    .title {
      @include title();
    }
    .drinpu {
      @include form-button();
      .p-dropdown-trigger {
        @include dropdown-trigger();
      }
      label {
        @include dropdown-label();
      }
      .p-dropdown {
        // width: 100px;
      }
      .p-dropdown-panel .p-dropdown-items {
        background: $dark-background;
      }
      .p-dropdown-panel {
        border: none;
        margin-top: -10px;
      }
      .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
      body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        color: $white;
      }
      .p-input-overlay-visible {
        background-color: transparent;
      }
    }
    #registerFirstname,
    .register-lastname {
      flex-grow: 0.3;
      input {
        padding-left: 30px;
      }
    }
    .nameinpu {
      width: 100%;
      height: 49px;
      background: $dark-background;
      opacity: 0.8;
      border-radius: 30px;
      font-size: 16px;
      color: $white;
      border: none;
      padding-left: 15px;
    }
  }
  .signUpProfileBoxDOBFields {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: 'center';
    margin-bottom: 35px;
    .title {
      @include title();
    }
    .datePickerinput {
      height: 49px;
      background: $dark-background;
      opacity: 0.8;
      border-radius: 30px;
      font-size: 16px;
      color: $white;
      border: none;
      padding: 0 15px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .drinpu {
      @include form-button();
      .p-dropdown-trigger {
        @include dropdown-trigger();
      }
      label {
        @include dropdown-label();
      }
      .p-dropdown {
        width: 100px;
      }
      .p-dropdown-panel .p-dropdown-items {
        background: $dark-background;
        opacity: 0.8;
        // border-radius: 30px;
      }
      .p-dropdown-panel {
        border: none;
        margin-top: -10px;
      }
      .p-dropdown-panel .p-dropdown-items .p-dropdown-item,
      body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
        color: $white;
      }
      .p-input-overlay-visible {
        background-color: transparent;
      }
    }
  }
  .nextsignu {
    @include button();
    padding: 0;
    background-color: $button-blue;
    border-radius: 30px;
    margin-bottom: 20px;
  }
  .submit-button {
    // max-width: 327px;
    // margin: 0 auto;
    // border-radius: 30px;
  }
}

.countryListDropdown {
  display: block;
  position: absolute;
  width: 327px !important;
  // height: 180px;
  overflow: hidden;
  /* overflow-y: scroll; */
  background: #17171d;
  // /* opacity: 0.8; */
  border-radius: 10px;
  z-index: 10;
  padding: 10px;
  // left: 0;
  top: 65%;
  padding-top: 0px;
  .searchInput {
    position: sticky;
    width: 100%;
    padding-left: 45px;
    color: #ffffff;
    top: 0;
  }
  .searchInputField {
    border-radius: 10px;
    width: 100%;
    padding-left: 15px;
    color: #ffffff;
    top: 0;
    background: $button-blue;
    border-radius: 30px;
    height: 29.89px;
    width: 212px;
  }
  .searchInputField::placeholder {
    color: $white;
  }
  .countryListDropdownScroller {
    width: 100%;
    height: 225px;
    overflow: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    font-size: 16px;
    color: $white;
    cursor: pointer;
    padding: 5px;
    text-align: left;
    display: flex;
    align-items: center;
  }

  .countryListDropdownScroller::-webkit-scrollbar {
    width: 8px;
  }
  .countryListDropdownScroller::-webkit-scrollbar-thumb {
    background: #4453d6;
    border-radius: 20px;
  }
  .countryListDropdownScroller::-webkit-scrollbar-track {
    background: #c4c4c4;
    border-radius: 10px;
  }
}

.progress-container {
  margin-bottom: 20px;
}
