.Markvetsellerpmylace {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
}
.maindivsellerconsole {
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100vw - 10px);
}
.Placedsellerfbh {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  color: #ffffff;
}
.marketplacedseelerovox {
  /* width: 1007px; */
  width: calc(100vw - 520px);
  min-height: 130px;
  border-radius: 10px;
  background: #333333;
  padding-top: 5px;
}
.mselllermyproduct {
  width: calc(100vw - 520px);
  min-height: 123px;
  border-radius: 10px;
  background: #ffffff;
  padding-top: 5px;
  margin-top: 20px;

}
.SliderBox3 {
  width: 208.22px;
  overflow: hidden;
  background: transparent;
  /* opacity: 0.3; */
  border-radius: 10px;
  padding-bottom: 10px;
  margin: auto;
  cursor: pointer;
  /* height: 300px; */
}

.SliderBox3 .sliderProductImg {
  width: 208px;
  height: 200px;
}

.SliderBox3 .ProductBox {
  width: 150px;
  margin-left: 20px;
}
.p-button {
  background: transparent !important;
  border: 0px !important;
  color: #ffffff !important;
  padding: 0px !important;
  box-shadow: none !important;
}

.p-carousel-prev span,
.p-carousel-next span {
  font-size: 50px !important;
}
.placedoedcdrhesoihjcl {
  background: #f5d028;
  border-radius: 0px 30px 30px 0px;
  width: 185px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-size: 16px;
  color: #17171d;
  text-align: center;
  line-height: 43px;
}
.placedoedcdrhesoihjcl span {
  font-weight: bold;
}
.myprodyctshmcdcd {
  background: #333333;
  border-radius: 0px 30px 30px 0px;
  width: 185px;
  height: 44px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  line-height: 43px;
}
.markesellerbackbutton {
  font-family: "Segoe";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff !important;
  background: transparent;
  border: none;
  margin-right: 31px;
}
.markesellerbackbutton:hover {
  background: transparent;
  /* color: #666666 !important; */
}
.markesellerbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.markesellerbackbutton span {
  font-family: "Segoe";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff;
}
.markesellerbackbutton img {
  width: 15px;
}
.siementomarke {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  margin: 0px;
  padding-left: 12px;
  line-height: 23px;
}
.ellipsisProductName{
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.siemencscmarke {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #17171d;
  margin: 0px;
  padding-left: 12px;
  line-height: 23px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
}
.DeviseleerceType {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #17171d;
  margin: 0px;
  padding-left: 12px;
}
.Locselllerornian {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #17171d;
  margin: 0px;
  padding-left: 12px;
}
.imagcarasoedvfdv {
  background: #17171d;
  width: 208px;
  height: 30px;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  opacity: 0.8;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.imagcarasoedvfdv p {
  background: #4aea87;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 0px;
  margin-right: 5px;
}
.sellerorderhistoryBox {
  background: #333333;
  border-radius: 10px;
  margin-top: 25px;
  /* width: 1007px; */
  min-height: 130px;
  padding-top: 5px;
  padding-bottom: 20px;
  width:calc(100vw - 500px);
  overflow: auto;
}
.sellerOrderHistoryTitleBox{
  width: 185px;
  height: 44px;

  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  margin-top: 17px;
  text-align: center;
  line-height: 43px;
}


.mainContainerSellerconsole {
  width: calc(100vw - 500px);
  overflow: hidden;
  display: inline-flex;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.mselllermyproduct .p-carousel-prev,
.mselllermyproduct .p-carousel-next {
  color: #000 !important;
}




.confirmOrderButton {
  background: #17171d !important;
  width: 208px;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 10px;
}

.confirmOrderButton span {
  background: #4aea87;
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 0px;
  margin-right: 5px;
}

.noSellerData{
    text-align: center;
    color: #FFFFFF;
    font-family: Segoe;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
}





.buyerOrderHistoryList{
  display: inline-flex;
  background: #17171D;
  opacity: 0.9;
  border-radius: 10px;
  padding: 18px;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 30px;
  cursor: pointer;
}

.buyerOrderHistoryList .orderDate{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #FFFFFF;
  min-width: 160px;
}
.buyerOrderHistoryList .orderID{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  min-width: 150px;
  
  color: #FFFFFF;
}
.buyerOrderHistoryList .orderName{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  min-width: 150px;

  color: #FFFFFF;
}
.buyerOrderHistoryList .orderPrice{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  color: #FFFFFF;
  min-width: 150px;
  text-align: center;
}
.buyerOrderHistoryList .orderStatus{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  color: #C4C4C4;
  min-width: 150px;
  text-align: center;
  display: inline-flex;
  justify-content: center;

}
.buyerOrderHistoryList .orderStatus div{
  width: 12px;
  height: 12px;
  border-radius: 50px;
  margin-right: 5px;
}

.noOrderHistory{
  text-align: center;
  color: #FFFFFF;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  /* padding-left: 200px; */
  text-align: center;
}


.draftProductButton {
  background: #4453D6;
  width: 208px;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 10px;
  border: none !important;
}


@media only screen and (min-width: 400px) and (max-width: 1025px) {


  .mainContainerSellerconsole {
    width: calc(100vw - 10px);
  }

  .marketplacedseelerovox, .mselllermyproduct {
    /* width: 1007px; */
    width: calc(100vw - 45px);

  }
  .sellerorderhistoryBox {
    background: #333333;
    border-radius: 10px;
    margin-top: 25px;
    /* width: 1007px; */
    min-height: 130px;
    padding-top: 5px;
    padding-bottom: 20px;
    width:100%;
    overflow: auto;
  }
}

@media only  screen and (min-width: 1920px){
  .mainContainerSellerconsole {
      width: calc(100vw - 500px);
      /* overflow: hidden; */
      display: inline-flex;
   }
   .maindivsellerconsole {
    padding-left: 25px;
    padding-right: 25px;
    width: calc(100vw - 800px);
    margin-left: 300px;
}
.markesellerbackbutton{
  margin-top: 20px;
}
.marketplacedseelerovox , .mselllermyproduct {
    /* width: 1007px; */
    width: calc(100vw - 850px);

  }
  .sellerorderhistoryBox {
    background: #333333;
    border-radius: 10px;
    margin-top: 25px;
    /* width: 1007px; */
    min-height: 130px;
    padding-top: 5px;
    padding-bottom: 20px;
    width:calc(100vw - 850px);
    /* max-width: 1070px; */
  }
}
