.Doyouwant {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  color: #ffffff;

}
.findmybusbutton {
  width: 327px;
  height: 54px;
  border-radius: 30px;
  background: #4453d6 !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin-top: 25px;
}
.regisretbutton {
  width: 327px;
  height: 54px;
  border-radius: 30px;
  border: 2px solid #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  background: transparent !important;
}
.businADDLAT {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding-top: 30px;
  cursor: pointer;
}
.CompleteBus {
  width: 327px;
  height: 27px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #c4c4c4;
  margin: 0 auto;
  text-align: center;
}
.PRIMARY {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
  margin-top: 28px;
}
.PRIMARY span{
  font-weight: bold;
}
.label1 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 10px;
  margin-bottom: 5px !important;
}
.businessInputBox {
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 490px;
  padding-left: 15px;
  color: #FFFFFF;
}

.label2 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding-left: 35px;
  margin-bottom: 5px !important;

}

.nationalityDropdown .p-dropdown-trigger{
  background: transparent  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
}

.nationalityDropdown label , .genderDropdown label , .drinpukyc label{
  background: transparent !important;
  color: #FFFFFF !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
}
.inpit4 {
 
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 300px;
  padding-left: 15px;
  color: #FFFFFF;

}

.inpurt5 {
 
background: #17171D;
opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 235px;
  padding-left: 15px;
  color: #FFFFFF;
}
.label3 {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding-left: 35px;
  margin-bottom: 5px !important;

}
.inputrd6 {
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 270px;
  padding-left: 15px;
  color: #FFFFFF;

}
.input7d {
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 270px;
  padding-left: 15px;
  color: #FFFFFF;
}
.input8 {
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 154px;
  padding-left: 15px;
  color: #FFFFFF;

}
.businessreginet {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
  margin-top: 61px;
}
.iunpityu9 {
  background: #333333;
  /* opacity: 0.7; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 160px;
  padding-left: 15px;
  margin-left: 19px;
}
.businessSizwlABEL {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
  padding-left: 15px;
  margin-bottom:5px !important;
}
.businessregidocu {
  padding-top: 27px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  width: 330px;
}
.PDForJPEG {
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-top: 10px;
  opacity: 0.5;
}
.regisdocsupload {
  width: 95px;
  height: 32px;
  background: #4453d6;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
  cursor: pointer;
}
.regisdocsDelete {
  width: 95px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171D;
  line-height: 32px;
  cursor: pointer;
}
.regiserbusineerror {
  width: 313px;
  height: 46px;
  background: #d80031;
  border-radius: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  padding: 5px;
  margin-top: 10px;
}
.radioinput {
  width: 22px;
  height: 22px;
  cursor: pointer;
  margin-right: 15px;
}
.Iagreewi {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  /* align-items: center; */
  color: #ffffff;
  justify-content: center;
  margin-top: 29px;
  cursor: pointer;
}
.Iagreewi a{
  color: #ffffff !important;

}
.submitresfgbutton {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
  border: none;
}
.additional {
  font-family: Segoe;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #eef3f7;
  margin-top: 70px;
}
.FINDBUSI {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #eef3f7;
  margin-top: 40px;
}
.FINDBUSI span{
  font-weight: bold;
}
.searchinput {
  /* line-height: 22px; */
  display: flex;
  align-items: center;
  background: #17171D;
  opacity: 0.9;
  border-radius: 30px;
  height: 49px;
  width: 508px;
}
.businesssearchinput {
  outline: none;
  border: none;
  color: #ffffff;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  background: transparent;
  width: 90%;
  padding-left: 10px;

}
.findbusinesstext {
  font-family: Segoe;
  color: #eef3f7;
  padding-top: 40px;
  width: 327px;
  height: 40px;
  margin: 0 auto;

  font-weight: normal;
  font-size: 20px;
  color: #C4C4C4;
}
.textlabeljcd {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  margin: 0px;
  padding-left: 15px;
}

.nomatchfounfd {
  width: 508px;
  height: 35px;
  background: #d80031;
  border-radius: 10px;
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  line-height: 33px;
}

.searchagainm {
  width: 327px;
  height: 54px;
  background: #4453d6 !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff !important;
  margin-top: 40px;
  border: none;
}
.resigisrterbutton2 {
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 30px;
  width: 327px;
  height: 54px;
  background: transparent;
  margin-top: 14px;
}
.resultfounddiv {
  width: 508px;
  height: 63px;
  background: #17171D;
  border-radius: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 35px;
  line-height: 60px;
}
.steredBusines {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  opacity: 0.6;
  padding-top: 16px;
}
.EasternBil {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
}
.doubletextrowdiv {
  display: flex;
  justify-content: space-between;
  width: 220px;
  align-items: center;
}

.searchBusiness {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #ffffff;
  padding-left: 15px;
  cursor: pointer;
}

.businessTypeDropdownBox {
  position: relative;
  z-index: 1;
  width: 445px;
  height: 49px;
  padding-left: 15px;
  border-radius: 30px;
  margin-right: 30px;
  
}
.businessTypeDropdownBox::before {
  /* width: 445px; */
  content: "";
  position: absolute;
  top:0;right:0;left:0;bottom:0;
  background: #17171D !important;
  border-radius: 30px;
  opacity: 0.9;
  z-index: -1;
  border: none;
   width: 100%;
  height: 100%;
}

.businessTypeDropdown{
  background: transparent !important;
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 430px;
  color: #FFFFFF;
  border: none !important;
  border-radius: 30px !important;
}

.yearEstablishBox{
  position: relative;
  z-index: 1;

}

.yearEstablishBox::before {
  content: "";
  position: absolute;
  top:0;right:0;left:0;bottom:0;
  background: #17171D;
  border-radius: 30px;
  opacity: 0.9;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.inpurt3 .p-dropdown-panel {
  background: #17171D !important;
  color: #FFFFFF !important;
  border-radius: 30px  !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  z-index:1024;
  border: 0px !important;
}


.inpurt3 {
  background: transparent !important;
  /* background: #17171D !important; */
  /* opacity: 0.9; */
  border-radius: 30px;
  border: none;
  height: 49px;
  width: 124px;
  padding-left: 15px;
  color: #FFFFFF;
  border: none !important;
  border-radius: 30px !important;
}

.inpurt3 .p-dropdown-trigger ,.businessTypeDropdown  .p-dropdown-trigger{
  background: transparent  !important;
  color: #FFFFFF !important;
  margin-right: 8px;
}

.inpurt3 label , .businessTypeDropdown label {
  background: transparent !important;
  color: #FFFFFF !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
}
.inpurt3 .p-dropdown-item{
  color: #FFFFFF !important;

}
.errorMessagePadding{
  padding-left: 25px; 
}
.mainBusinessContainer{
  height: calc(100vh - 100px);
  /* overflow: auto; */
  display: inline-grid;
  justify-content: center;
  margin: 20px;
  /* width: 100vw; */
}
.registerFirstStep{
  width: 327px;
  height: 233px;  
  margin: auto;
}
.registerChildCenter{
  margin: auto;
  width: calc(100vw - 20px);
  overflow-y: auto;
  overflow-x: auto;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;

}

.stepGoBackRegisterIcon{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #C4C4C4 !important;
  background: transparent !important;
  border: none !important;
  /* position: absolute;
  right: 10px; */

}
.stepGoBackRegisterIcon:hover{
  background: transparent;
  color: #C4C4C4;
}


.stepGoBackRegisterIcon div{
  width: 25px;
  height: 25px;
  background: #4453D6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.stepGoBackRegisterIcon span{
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
}
.stepGoBackRegisterIcon img{
  width: 15px;
}


/* .mainbusinesregustr{
  background: #17171D50;
  border-radius: 30px;
  padding: 30px;
} */

  .mainbusinesregustr{
    border-radius: 30px;
    padding: 30px;
    z-index: 1;
    position: relative;
  }
  .mainbusinesregustr::before{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #17171D;
    border-radius: 30px;
    opacity: 0.5;
    z-index: -1;
    width: 100%;
    height: 100%;
    margin: 10px;
  }
.filterByText{
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding-left: 50px;
  color: #FFFFFF;
  margin-top:10px;
}
.filterByText label{
  padding-left:20px;
  padding-top: 6px; 
}
.searchBusinessList{
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
  padding-left: 20px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #FFFFFF;
  border-radius: 10px;
  background: #17171D;
  opacity: 0.9;

}


.foundResults{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #FFFFFF;
  padding-left: 15px;
}
.foundResults span{
  font-weight: bold;
  margin-right: 5px;
}

.filenameText{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-decoration-line: underline;
  padding-top: 14px;
  color: #FFFFFF;
  padding-left: 20px;
}
.mainBusinessWrapper{
  width: 100vw;
  height: calc(100vh - 80px);
  overflow-y: auto;
    /* position: absolute;
    margin: auto;
    top: 60px;
    bottom: 0;
    right: 0;
    left: 0; */
}

.businessRegisterProgressbar {
  width: 327px;
  height: 7px;
  background: #FFFFFF !important;
  border-radius: 10px;
  /* margin-left: 31px; */
}
.businessRegisterProgressbar .progress-bar {
  background-color: #4AEA87 !important;
  border-radius: 10px !important;

}
@media only  screen and (min-width: 1920px){
  .mainBusinessContainer{
    width: calc(100vw - 294px);
    margin-left: 294px;
  }
  .mainBusinessGobackBox{
    width: calc(100vw - 294px);
  }
}