.selleraddDEtailContainer {
  display: inline-flex;
  /* background: linear-gradient(180deg, #4453d6 0%, #571b65 100%); */
  /* height: calc(100vh - 85px); */

}
.selleradddsbackbutton {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: inline-flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: none;
  margin-top: 5px;
  margin-right: 31px;
}
.selleradddsbackbutton:hover {
  background: transparent;
  color: #ffffff;
}
.selleradddsbackbutton div {
  width: 25px;
  height: 25px;
  background: #4453d6;
  border-radius: 4px;
  margin-right: 10px;
  padding: 1px;
  margin-right: 5px;
  padding-right: 5px;
}
.selleradddsbackbutton span {
  font-family: 'Segoe';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-left: 5px;
  color: #ffffff;
}
.selleradddsbackbutton img {
  width: 15px;
}
.selleradddetailmaindiv {
  display: flex;
  padding: 30px;
  height: calc(100vh - 128px);
  width: calc(100vw - 500px);
  overflow-y: auto;
  overflow-x: auto;
}
.selleraddSmensAvapro {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #ffffff;
}
.selleraddSmensAvapro p {
  font-size: 35px;
  margin: 0px;
}
.sellerinppuaddt {
  background: #17171d;
  opacity: 0.7;
  border-radius: 30px;
  width: 470px;
  height: 49px;
  border-radius: 30px;
  border: none;
  color: #ffffff;
}
.selaionrfduaddt {
  background: #17171d;
  opacity: 0.7;
  border-radius: 30px;
  width: 216px;
  height: 49px;
  border-radius: 30px;
  border: none;
  color: #ffffff;

}
.inpursellrlabel {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
  padding-left: 15px;
  padding-top: 15px;
}
.sellerGENERAL {
  font-family: Segoe;
  font-style: normal;
  font-size: 24px;
  color: #ffffff;
  padding-top: 25px;
}
.sellerGENERAL span {
  font-weight: bold;
}
.selleriunpolodimg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #ffffff;
  opacity: 0.8;
  margin: 0px;
}
.addprosavebutton {
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  background: #ffffff !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d !important;
  margin-left: 30px;
}
.addsubmittomarket {
  width: 218px;
  height: 40px;
  border: none;
  background: #F5D028  !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171D;
  margin-left: 18px;
}
.unlistSubmit {
  width: 218px;
  height: 40px;
  border: none;
  background: #17171D  !important;
  border-radius: 30px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #FFFFFF;
  margin-left: 18px;
}
.dropdoowndicaddpro {
  /* width: 441px;
  height: 868px; */
  background: #ffffff;
  border-radius: 10px;
  margin-top: 20px;
  width: 600px;
  padding-top: 5px;
  /* overflow-x: auto; */
}
.dropdoowndicaddpro .p-dropdown{
  border: none !important;
  height: 35px;
}
.dropdoowndicaddpro .p-dropdown label{
  padding: 10px !important;
}
.dropdoowndicaddpro  .p-dropdown .p-dropdown-trigger{
  margin-top: 4px !important;
}

.speciaddatisheading {
  width: 144px;
  height: 37px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}

.overViewheading {
 /* width: 111px; */
  /* max-width: 151px; */

  height: 37px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}


.listItems{
  display: inline-flex;
  min-height: 45px;
}
.addproductManuurer {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #17171d;
  width: 294px;
  padding-left: 10px;
  margin-bottom: 0px;
  margin: auto;
  /* margin-top: 10px !important; */
  /* margin-bottom: 0px; */
}
.logisticssheading {
  width: 101px;
  height: 37px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}
.titleinfossheading {
  width: 172px;
  height: 37px;
  background: #4453d6;
  border-radius: 0px 30px 30px 0px;
  margin-top: 17px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
}
.selleruploadimgbox {
  width: 100px;
  height: 100px;
  background: rgba(12, 15, 29, 0.3);
  border: 1px dashed rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  margin-top: 12px;
  cursor: pointer;
}
.psellerlusimg {
  width: 30px;
  height: 30px;
  margin-top: 32px;
}
.imgwerrormsdiv {
  background: #d80031;
  border-radius: 10px;
  width: 352px;
  height: 60px;
  margin-top: 19px;
  font-family: Helvetica;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 9px;
}
.sellerpriviewimgBox{
  width: 100px;
  height: 100px;
  margin-left: 13px;
  margin-top: 10px;
}

.sellerpriviewimg {
  width: 86px;
  height: 86px;
  border-radius: 86px;
  margin-left: 10px;
  /* position: relative; */
}
.sellrremoveimgbutton {
  border: 0px;
  float: right;
  position: relative;
  top: -33px;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  background: #ffffff;
  left: 16px;
  text-align: center;
}
/* .sellrremoveimgbutton:hover,
.sellrremoveimgbutton:active,
.sellrremoveimgbutton:focus {
  background: #FFFFFF !important;
  border: 0px;
  float: right;
  position: relative;
  top: -45px;
} */
.consoletypeinpt {
  border: none;
  width: 235px;
  height: 40px;
  background: #333333 !important;
  margin-right: 15px;
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  padding-left: 9px;
  color: #ffffff !important;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;


}
.consoletypeinpt :focus {
  background: #333333;
}

.sellrdeletebuttonimg {
  position: absolute;
  top: 52px;
  background: #ffffff;
  left: 76px;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  text-align: center;
}
.previewImageBox {
  width: 120px;
  height: 100px;
  margin-left: 20px;
}
.sellerremovefilebutton {
  background: #4453d6 !important;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  border: none !important
}
.replaceReport{
  background: #C4C4C4 !important;
  color: #17171D !important;
  border: none !important;
  width: 140px;
  text-align: center;
  border-radius: 20px;
  height: 40px;
  padding-top: 10px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  cursor: pointer;
}

.uploadfileypeinpt {
  cursor: pointer;
  border: none;
  width: 235px;
  height: 45px;
  background: #333333;
  margin-right: 15px;
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  line-height: 42px;
  margin-top: 15px;
}
/* .p-dropdown label.p-dropdown-label {
  cursor: pointer;
  background: #333333;
  border-radius: 30px;
  border: none;
  border-color: #333333;
  width: 235px;
  height: 40px;
  border: 0px;
} */
/* body .p-dropdown {
  background: #333333;
  /* border: 1px solid #a6a6a6; */
/* transition: border-color 0.2s, box-shadow 0.2s; */
/* border-radius: 30px; */
/* }  */
/* body .p-dropdown .p-dropdown-trigger {
  background-color: #333333;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #FFFFFF;
  border-radius: 30px;
} */
/* body .p-inputtext {
  font-size: 14px;
  color: #ffffff;
  background: #ffffff;
  padding: 8px;
  border: 1px solid #a6a6a6;
  transition: border-color 0.2s, box-shadow 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 3px;
  padding-left: 15px;
} */

.sellereencetyinput {
  background: #17171d !important;
  border-radius: 30px !important;
  width: 235px;
  height: 49px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  /* opacity: 0.7; */
}
.sellerdropcopeinpt1 .p-dropdown{
  background: transparent !important;
  border: none !important;
}

.sellerdropcopeinpt1 .p-dropdown-trigger ,.sellereencetyinput .p-dropdown-trigger {
  background: transparent !important;
  color: #ffffff !important;
  border-radius: 30px !important;
 
}
/* .locationBox{
  background: red;
} */
.sellerdropcopeinpt1 .p-dropdown .p-dropdown-label{
  height:48px !important;
  opacity: 0.7;
  background: #17171d !important;
}

/* .sellerdropcopeinpt1 .p-dropdown .p-dropdown-label{
  opacity: 0.8;
} */
.listItems .p-dropdown .p-dropdown-trigger {
  right: 10px;
  top: 5px;
  height: 30px;
  opacity: 0.8;
}

.sellerdropcopeinpt1 .p-dropdown .p-dropdown-trigger {
  right: 10px;
  top: 5px;
  height: 40px;
}
.sellereencetyinput .body .p-dropdown .p-dropdown-trigger{
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}
.sellereencetyinput label,
.sellereencetyinput label,
.sellereencetyinput label,
.sellereencetyinput .p-dropdown-panel {
  background: #17171d !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  border: none !important;
}
.selleraddDEtailContainer .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover, body .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group:not(.p-highlight):not(.p-disabled):hover{
  /* background: red !important; */
  background: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
  color: #17171D !important;
}
.sellereencetyinput li {
  color: #ffffff !important;
  border-radius: 10px;
}
.sellerdropcopeinpt {
  border: none;
  width: 235px;
  height: 35px;
  background: #333333;
  /* margin-right: 15px; */
  border-radius: 30px;
  font-family: Segoe;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
}


.sellerdropcopeinpt .body .p-dropdown {
  background: #333333 !important;
  border-radius: 30px !important;
}
.sellerdropcopeinpt .p-dropdown-trigger {
  background: transparent !important;
  color: #ffffff !important;
  /* margin-right: 8px; */
  border-radius: 30px !important;
 
}
.sellerdropcopeinpt label,
.sellerdropcopeinpt label,
.sellerdropcopeinpt label,
.sellerdropcopeinpt .p-dropdown-panel {
  background: #333333 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  font-family: 'Segoe';
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  border: none !important;
}
.sellerdropcopeinpt li {
  color: #ffffff !important;
  border-radius: 10px;
}
.removeImageIConsButton{
  background: #ffffff !important;
  border: 0px;
  /* position: relative;
  top: 23px;
  right: 35px; */
  padding: 0px;
  border-radius: 30px;
  opacity: 0.8;
  float: right;
  position: relative;
  top: -28px;
}
.previewImageListing{
  display: inline-flex;
  width: 400px;
  flex-wrap: wrap;
}

.updateListingbutton {
  width: 169.58px;
  height: 40px;
  border-radius: 30px;
  border: none;
  background: #F5D028 !important;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #17171d !important;
  margin-left: 30px;
}

.selleradddetailmaindiv .p-dropdown .p-dropdown-label{
  text-align: center;
}




.AddProductConfirmOrderModal{
  background:#4453D6;
  border-radius: 30px;
  padding: 25px;
}
.AddProductConfirmOrderModal .confirmmsg{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;

  color: #FFFFFF;

  opacity: 0.8;
}
.AddProductConfirmOrderModal .confirmTitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;
  margin-bottom: 15px;
  
}
.AddProductConfirmOrderModal .buttons{
  min-width: 100px;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #FFFFFF;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #FFFFFF ;
  height: 40px;
  margin-top: 15px;


}
.AddProductConfirmOrderModal .yesButton{
  background:#17171D !important;
  margin-left: 10px;
  border: none !important;
}

.AddSuccessModal{
  background: #17171D;
  border-radius: 30px;
}
.AddSuccessModal .heading{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  color: #FFFFFF;
}
.AddSuccessModal .boxImage{
  width: 142px;
  height: 129px;
}
.AddSuccessModal .cartImage{
  width: 114px;
  height: 109px;
}

.AddSuccessModal .subtitle{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 0px;
}
.AddSuccessModal .description{
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.6
}
.AddSuccessModal .returnSellerConsole{
  background: #4453D6;
  border-radius: 30px;
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
align-items: center;
text-align: center;
width: 100%;
padding: 10px;
color: #FFFFFF;
border: none !important;
}
.AddSuccessModal  .backDahboard{
  font-family: Segoe;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
/* or 129% */

text-align: center;

color: #C4C4C4;
width: 100%;
padding: 10px;
color: #FFFFFF;
border: none !important;
background: transparent;
opacity: 0.6;

}
.AddSuccessModal .closeButton{
  background: transparent;
  border: none !important;
  padding: 0px;
  float: right;

}
.manufactureDatePicker{
  display:flex;
  align-items: center;
  cursor: pointer;
  justify-content:center;
}

@media only screen and (min-width: 400px) and (max-width: 1025px) {
  .selleradddetailmaindiv {
    width: calc(100vw - 10px);
  }

}
@media only  screen and (min-width: 1920px){

  .selleradddetailmaindiv {
    display: flex;
    padding: 30px;
    width: calc(100vw - 800px);
    margin-left: 300px
  }
}
.addProductTextArea{
  background: #17171D;
  opacity: 0.7;
  border-radius: 20px;
  width: 470px;
  min-height: 100px;
  padding: 15px;
  border: none !important;
  color: #FFFFFF !important;
  outline: none !important;
  resize: none;
}

.conditionNote{
  border-radius: 20px;
  height: 80px;
  resize: none;
  text-align: left;
  padding: 10px;
  border: none !important;
  outline: none !important;
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}



