.productBrowseContainer {
  /* background: '#FFFFFF'; */
  height: calc(100vh - 84px);
  display: inline-flex;
}

.Browsinxsdcg {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  color: #17171d;
}
.arketplaceresults {
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #45443c;
  padding-left: 15px;
  padding-top: 9px;
}
.arketplaceresults span {
  font-weight: bold;
}
.maindivbrowsese {
  height: calc(100vh - 80px);
  padding-left: 25px;
  padding-right: 25px;
  width: calc(100vw - 500px);
  overflow-x: auto;
}

.marketBrowseProductinput {
  background: #333333 !important;
  border-radius: 30px !important;
  min-width: 150px;
  height: 40px;
  border: none !important;
  color: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  margin-right: 15px;
}
.marketBrowseProductinput .p-dropdown-trigger {
  background: #333333 !important;
  color: #ffffff !important;
  /* margin-right: 8px; */
  border-radius: 30px !important;
}
.marketBrowseProductinput .body .p-dropdown .p-dropdown-trigger {
  background: #333333 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
}
.marketBrowseProductinput label,
.marketBrowseProductinput label,
.marketBrowseProductinput label,
.marketBrowseProductinput .p-dropdown-panel {
  background: #333333 !important;
  color: #ffffff !important;
  border-radius: 30px !important;
  font-family: "Segoe";
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  margin-top: 3px;
}
.marketBrowseProductinput li {
  color: #ffffff !important;
  border-radius: 10px;
  font-size: 15px !important;
}

.productHorizontalListView {
  display: inline-flex;
  margin: 10px;
  flex-wrap: wrap;
}

.marketBrowseProductSortByinput {
  color: #333333 !important;
  border-radius: 30px !important;
  width: 250px;
  height: 40px;
  border: 2px solid #333333 !important;
  background: #ffffff;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  padding-left: 10px;
  margin-right: 15px;
}

.marketBrowseProductSortByinput .p-dropdown-trigger {
  color: #333333 !important;
  background: #ffffff !important;
  /* margin-right: 8px; */
  border-radius: 30px !important;
}
.marketBrowseProductSortByinput .body .p-dropdown .p-dropdown-trigger {
  color: #ffffff !important;
  background: #333333 !important;
  border-radius: 30px !important;
  margin-right: 10px;
}
.marketBrowseProductSortByinput .p-inputtext {
  color: #333333 !important;
  background: #ffffff !important;
  border-radius: 26px;
}
/* .marketBrowseProductSortByinput label,
  .marketBrowseProductSortByinput label,
  .marketBrowseProductSortByinput label,
  .marketBrowseProductSortByinput .p-dropdown-panel {
    color: #FFFFFF !important;
    background: #17171D !important;
    border-radius: 30px !important;
    font-family: 'Segoe';
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
  } */
.marketBrowseProductSortByinput li {
  color: #ffffff !important;
  border-radius: 10px;
  font-size: 15px !important;
}
.marketBrowseProductSortByinput .p-dropdown-items-wrapper {
  color: #ffffff !important;
  background: #333333 !important;
  border-radius: 20px;
}
.noBrowseData {
  text-align: center;
  color: #333333;
  font-family: Segoe;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 19px;
  text-align: center;
  margin-top: 50px;
}

@media only screen and (min-width: 1920px) {
  .maindivbrowsese {
    padding-left: 325px;
  }
  .productBrowseContainer {
    position: absolute;
    top: 20px;
    width: 100vw;
  }
}
